<template>
  <div class="container-600">
    <div class="explore-navbar d-none d-md-flex">
      <div class="d-inline-flex explore-navbar-container-one">
        <button
          class="filter-button"
          @click="triggerSidebar()"
        >
          <font-awesome-icon
            icon="fa-solid fa-bars-filter"
            size="xl"
            class="filter-icon "
          />
        </button>
        <div
          class="action-loader"
          v-loading="loading"
        ></div>
      </div>

      <div class="count-container">
        <span>{{ $store.getters.qtt }} {{ $tc('explore.point', $store.getters.qtt).toUpperCase() }}</span>
      </div>

      <div class="action-dropdown-container">
        <el-dropdown
          trigger="click"
          class="mr-2"
          split-button
          v-if="$store.getters.selectedReports.length != 0"
          :type="$store.getters.selectionMode ? 'primary' : 'default'"
          @command="handleCommand"
          v-show="this.$store.getters.currentCard == 'table'"
          @click="enterSelectionMode"
        >
          <span :class="$store.getters.selectionMode ? 'el-dropdown-link-selectionMode' : 'el-dropdown-link'">
            {{ $t('explore.selected').toUpperCase() }}: {{ $store.getters.selectedReports.length }} {{ $tc('explore.point', $store.getters.selectedReports.length).toUpperCase() }}
          </span>
          <el-dropdown-menu slot="dropdown">
            <b-row class="mx-2">
              <b-col cols="6">
                <el-dropdown-item
                  id="btnOpen"
                  command="OPEN"
                  class="text-left dropdown-action-item dropdown-action-open"
                >{{ $t('status.open') }}</el-dropdown-item>
              </b-col>
              <b-col cols="6">
                <el-dropdown-item
                  id="btnAddressed"
                  command="ADDRESSED"
                  class="text-left dropdown-action-item dropdown-action-addressed"
                >{{ $t('status.addressed') }}</el-dropdown-item>
              </b-col>
            </b-row>
            <b-row class="mx-2">
              <b-col cols="6">
                <el-dropdown-item
                  command="ONGOING"
                  class="text-left dropdown-action-item dropdown-action-ongoing"
                >{{ $t('status.ongoing') }}</el-dropdown-item>
              </b-col>
              <b-col cols="6">
                <el-dropdown-item
                  command="SOLVED"
                  class="text-left dropdown-action-item dropdown-action-solved"
                >{{ $t('status.solved')  }}</el-dropdown-item>
              </b-col>
            </b-row>
            <hr>
            <b-row class="mx-2">
              <b-col cols="6">
                <el-dropdown-item
                  command="CLOSED"
                  class="text-left dropdown-action-item dropdown-action-closed"
                >{{ $t('status.closed')  }}</el-dropdown-item>
              </b-col>
              <b-col cols="6">
                <el-dropdown-item
                  command="ONHOLD"
                  class="text-left dropdown-action-item dropdown-action-onHold"
                >{{ $t('status.onhold')  }}</el-dropdown-item>
              </b-col>
            </b-row>
            <b-row class="mx-2">
              <b-col cols="6">
                <el-dropdown-item
                  command="NOACTION"
                  class="text-left dropdown-action-item dropdown-action-noAction"
                >{{ $t('status.noaction')  }}</el-dropdown-item>
              </b-col>
              <b-col cols="6">
                <el-dropdown-item
                  command="DELETED"
                  class="text-left dropdown-action-item dropdown-action-deleted"
                >{{ $t('status.deleted')  }}</el-dropdown-item>
              </b-col>
            </b-row>

            <hr>

            <hr>

            <el-dropdown-item command="WI">
              <font-awesome-layers class="mx-2">
                <font-awesome-icon
                  icon="fa-light fa-square"
                  transform="grow-6 rotate-45"
                />
                <font-awesome-layers-text
                  class="gray8"
                  transform="shrink-6"
                  value="WI"
                />
              </font-awesome-layers>
              {{ $t('action.wi')  }}
            </el-dropdown-item>

            <el-dropdown-item command="COMMENT">
              <font-awesome-icon
                icon="fa-light fa-comment"
                size="lg"
                class="mx-2"
              />{{ $t('action.comment')  }}
            </el-dropdown-item>

            <el-dropdown-item command="ATTACHMENT">
              <font-awesome-icon
                icon="fa-light fa-paperclip"
                size="lg"
                class="mx-2"
              />{{ $t('action.attachment')  }}
            </el-dropdown-item>

            <hr>

            <el-dropdown-item command="pdfSimple">
              <font-awesome-icon
                icon="fa-light fa-file-pdf"
                size="lg"
                class="mx-2"
              />{{ $t('action.PDFsingle')  }}
            </el-dropdown-item>
            <el-dropdown-item command="pdfSynthesis">
              <font-awesome-icon
                icon="fa-solid fa-file-pdf"
                size="lg"
                class="mx-2"
              />{{ $t('action.PDFsynthesis')  }}
            </el-dropdown-item>
            <hr>
            <el-dropdown-item command="xlsExport">
              <font-awesome-icon
                icon="fa-light fa-file-excel"
                size="lg"
                class="mx-2"
              />{{ $t('action.exportXlS')  }}
            </el-dropdown-item>
            <hr>
            <el-dropdown-item command="EDIT">
              <font-awesome-icon
                icon="fa-light fa-pen-square"
                size="lg"
                class="mx-2"
              /> {{ $t('action.edit')  }}
            </el-dropdown-item>

          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <el-button
        class="mr-2"
        @click="emptySelection"
        v-if="$store.getters.selectedReports != 0 && $store.getters.currentCard != 'tiny' && $store.getters.currentCard != 'map'"
      ><i class="el-icon-close el-icon-right"></i> {{ $t('explore.clear').toUpperCase() }}</el-button>

      <el-button
        class="mr-2 ml-0"
        @click="exitSelection"
        v-if="$store.getters.selectionMode"
      >
        <font-awesome-icon
          icon="fa-light fa-arrow-right-from-bracket"
          size="sm"
        />
        {{ $t('explore.exit').toUpperCase() }}
      </el-button>

      <div class="explore-mode">
        <el-button-group>
          <el-button
            class="explore-mode-btn"
            id="tiny"
            @click="switchTab"
          >
            <font-awesome-icon icon="fa-solid fa-grid" />
          </el-button>
          <el-button
            class="explore-mode-btn"
            id="map"
            @click="switchTab"
          >
            <font-awesome-icon icon="fa-solid fa-map" />
          </el-button>
          <el-button
            class="explore-mode-btn"
            id="table"
            @click="switchTab"
          >
            <font-awesome-icon icon="fa-solid fa-list" />
          </el-button>
        </el-button-group>

      </div>

    </div>
    <div
      id="mySidebar"
      class="filter-sidebar d-none d-sm-block"
      ref="sideBarFilter"
    >
      <Filters purpose='EXPLORE' />
    </div>

    <div id="main">
      <component
        v-bind:is="currentTabComponent"
        class="tab"
      ></component>

    </div>

    <ActionControl />
    <ModalAddress ref="addressModal" />
    <ModalWI />
    <ModalComments />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { reportActionMixin } from '../../mixins/reportActionMixin';
import Filters from '../../components/SideBarFilter.vue';
import ActionControl from '../../components/Explore/ActionControl.vue';
import TinyView from '../../components/Explore/TinyCardView.vue';
import TableView from '../../components/Explore/TableView.vue';
import MapView from '../../components/Explore/MapView.vue';
import ModalAddress from '@/components/Modals/ModalAddress.vue';
import ModalWI from '@/components/Modals/ModalWI.vue';
import ModalComments from '@/components/Modals/ModalComments.vue';

export default {
  name: 'explore',
  mixins: [reportActionMixin],
  components: {
    Filters,
    ActionControl,
    TinyView,
    TableView,
    MapView,
    ModalAddress,
    ModalWI,
    ModalComments,
  },
  data() {
    return {
      sidebarOpen: this.$store.getters.filterSidebar,
      currentTab: '',
      loading: false,
    };
  },
  beforeDestroy() {
    const navbar = document.getElementById('navbar');
    navbar.classList.add('navbar-shadow');
  },
  mounted() {
    this.$store.commit('loading', true);
    const navbar = document.getElementById('navbar');
    navbar.classList.remove('navbar-shadow');
    const { path } = this.$route;
    this.SAVE_CONTEXT(path);
    const tabBtn = document.getElementById(this.$store.getters.currentCard);
    console.log(tabBtn);
    tabBtn.classList.add('el-btn-selected');

    // if (this.isMobile()) {
    //   this.$store.commit('setCurrentCard', 'tiny');
    // }

    if (this.sidebarOpen) {
      this.openNav();
    } else {
      this.closeNav();
    }

    this.fitMobile();
  },
  created() {
    setTimeout(() => {
      this.fitMobile();
    }, 1000);

    window.addEventListener('resize', this.fitMobile);
  },
  destroyed() {
    window.removeEventListener('resize', this.fitMobile);
  },
  computed: {
    currentTabComponent: function () {
      return this.$store.getters.currentCard.toLowerCase() + '-view';
    },
  },
  methods: {
    ...mapMutations(['SAVE_CONTEXT']),
    emptySelection() {
      this.$store.commit('deleteSelection');
      // this.$message({
      //   message: 'Selection is now empty.',
      //   duration: 1000,
      // });
      this.$store.commit('setSelectionMode', false);
    },
    exitSelection() {
      this.$store.commit('setSelectionMode', false);
    },
    enterSelectionMode() {
      this.$store.commit('setSelectionMode', true);
    },
    fitMobile() {
      if (window.matchMedia('screen and (max-width: 480px)').matches) {
        // TODO: this is called on scroll for mobiles (add alert to notice)
        this.$store.commit('setCurrentCard', 'tiny');
        document.getElementById('main').style.marginLeft = '0';
        document.getElementById('main').style.marginRight = '0';
        document.getElementById('main').style.width = '100%';
      }
    },
    isMobile() {
      if (window.matchMedia('screen and (max-width: 768px)').matches) {
        return true;
      }
    },
    triggerSidebar() {
      if (!this.sidebarOpen) {
        console.log('OPEN');
        this.openNav();
      } else {
        console.log('CLOSE');
        this.closeNav();
      }
      this.sidebarOpen = !this.sidebarOpen;
    },
    openNav() {
      this.$store.commit('filterSidebar', true);
      document.getElementById('mySidebar').style.width = '392px';
      document.getElementById('mySidebar').style.opacity = '100';
      document.getElementById('main').style.marginLeft = '392px';
      const width = document.body.offsetWidth - 392;
      document.getElementById('main').style.width = `${width}px`;
      // const width = document.body.offsetWidth - 392;
      // document.getElementById('main').style.width = `${width}px`;

      // If on tableView mode
      if (this.$store.getters.currentCard == 'table') {
        document.getElementById('infiniteScrollTable').style.borderLeft =
          '1px solid rgb(229, 232, 235)';
      }
    },
    closeNav() {
      this.$store.commit('filterSidebar', false);
      document.getElementById('mySidebar').style.opacity = '0';
      document.getElementById('mySidebar').style.width = '52px';
      document.getElementById('main').style.marginLeft = '52px';
      const width = document.body.offsetWidth - 104;
      document.getElementById('main').style.width = `${width}px`;
      // If on tableView mode
      if (this.$store.getters.currentCard == 'table') {
        document.getElementById('infiniteScrollTable').style.borderLeft =
          'none';
      }
    },
    switchTab(e) {
      // currentTab = 'tiny-card'

      const tinyBtn = document.getElementById('tiny');
      const mapBtn = document.getElementById('map');
      const tableBtn = document.getElementById('table');
      tinyBtn.classList.remove('el-btn-selected');
      mapBtn.classList.remove('el-btn-selected');
      tableBtn.classList.remove('el-btn-selected');

      this.currentTab = e.currentTarget.id;

      this.$store.commit('setCurrentCard', e.currentTarget.id);
      const tabBtn = document.getElementById(this.currentTab);
      tabBtn.classList.add('el-btn-selected');
    },
  },
};
</script>
<style scoped>
.explore-navbar {
    position: fixed;
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0px 52px;
    top: 52px;
    width: 100%;
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 10px;
}
.filter-button {
    background: rgb(255, 255, 255);
    /* display: flex; */
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    /* justify-content: center; */
    width: 42px;
    height: 42px;
    padding: 12px;
    border: none;
    border-radius: 50%;
}
.filter-button:hover {
    transition: all 0.2s ease 0s !important;
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 10px !important;
}


@media (min-width: 1200px) {
  .explore-navbar-container-one {
    width: 360px;
  }
}


.action-dropdown-container {
    /* width: 400px; */
    /* margin-right: 0;*/
    margin-left: auto; 
    /* display: flex; */
    /* justify-content: right; */
}
/* .action-dropdown-container.el-button {
    padding: 40px 20px;
} */
/* .action-dropdown-container.el-button-group.el-button:hover, .action-dropdown-container.el-button-group.el-button:focus  {
    background: rgba(229, 232, 235, 0.5) !important;
    color: #606266 !important;
    border-color: rgb(229, 232, 235) !important;
} */
/* .el-button:hover {
     background: rgba(229, 232, 235, 0.5) !important;
    color: #606266 !important;
    border-color: rgb(229, 232, 235) !important;
} */


.explore-mode {
    /* width: 200px;; */
    /* margin-right: 0; */
    /* margin-left: auto; */
    /* display: flex;
    justify-content: right; */
}
/* Explore mode button grou[ */
.explore-mode-btn {
    border-color: rgb(229, 232, 235) !important;
}
.explore-mode-btn:hover, .explore-mode-btn:focus {
    background: rgba(229, 232, 235, 0.5);
    color: rgb(4, 17, 29);
    border-color:  rgb(229, 232, 235) !important;
}
.el-btn-selected {
  color: rgb(4, 17, 29);
    border-color: rgb(229, 232, 235);
    background-color: rgba(229, 232, 235, 0.5);
}
.el-button {
    border-radius: 12px;
    border-color:  rgb(229, 232, 235) !important;
    /* border: 2px solid; */
}
.el-button:focus, .el-button:hover {
    background: rgba(229, 232, 235, 0.5) !important;
    color: #606266 !important;
    border-color: rgb(229, 232, 235) !important;
}



/* .el-button:focus, .el-button:hover {
    color: #409EFF;
    border-color: #c6e2ff;
    background-color: #ecf5ff;
} */

.el-button-group>.el-button:first-child {
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
    /* border: 2px solid; */
}
.el-button-group>.el-button:not(:first-child):not(:last-child) {
    /* border-top: 2px solid; */
    /* border-bottom: 2px solid; */
}
.el-button-group>.el-button:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    /* border: 2px solid; */
}

.el-dropdown-menu {
    border-radius: 12px;
}
.el-dropdown-menu>.el-button-group>.el-button:first-child {
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
}
.dropdown-action>.el-button-group>.el-button:not(:first-child) {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.filter-sidebar {
    position: fixed;
    top: 120px;
    /* height: 100%; */
    height: calc(100vh - 120px);
    width: 0;
    z-index: -1;
    left: 0;
    background-color: #fff;
    overflow-x: hidden;
    /* transition:  0.5s; */
    transition: all 0.2s ease 0s !important;
    transition: alpha 0.2s;
    /* padding-top: 150px; */
}


/* 
.el-popper .popper__arrow {
    display: none !important;
}
.el-popper .popper__arrow, .el-popper .popper__arrow::after {
    display: none !important;
}

.select:hover .el-input__inner {
    border-color: rgb(229, 232, 235) !important;
}
.el-select .el-input.is-focus .el-input__inner {
    border-color: rgb(229, 232, 235) !important;
}
.el-select .el-input__inner:focus {
    border-color: rgb(229, 232, 235) !important;
} */

.action-loader { 
    margin-left: 20px;
}

#main {
    /* width: 100%; */

    width: calc(100% - 392px);
    overflow: hidden;
    top: 120px;
    transition: margin-left .5s;
    z-index: -1;
    margin-right: 52px;
    background-color: #ffffff;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
    #main {
        position: fixed;
        padding: 16px;
    }
}


/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidebar {padding-top: 15px;}
  .sidebar a {font-size: 18px;}
}


.dropdown-action-item {
    white-space: nowrap;
}




</style>