<template>
  <div class="chart-container">
    <highcharts
      :options="chartOptions"
      ref="highchart"
    ></highcharts>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Chart } from 'highcharts-vue';

export default {
  name: 'category',
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          height: '700',
        },
        credits: {
          enabled: false,
        },
        title: {
          text: this.getTitle(),
          align: 'left',
          style: { fontSize: '16px' },
        },
        subtitle: {
          text: this.$t('highcharts.overPeriod'),
          align: 'left',
          style: { fontSize: '10px' },
        },
        tooltip: {
          pointFormat: '<b>{point.percentage:.1f}% ({point.y})</b>',
        },
        accessibility: {
          point: {
            valueSuffix: '%',
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              format:
                '<b>{point.name}:</b> <span style="opacity: 0.5">{point.percentage:.1f}% ({point.y})</span>',
              style: {
                fontWeight: 'normal',
              },
            },
            showInLegend: true,
          },
        },
        series: [
          {
            name: 'Families',
            colorByPoint: true,
            data: [], // Initially empty
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                {
                  text: this.$t('highcharts.viewFullscreen'),
                  onclick: function () {
                    this.series[0].chart.fullscreen.toggle();
                  },
                },
                'separator',
                {
                  text: this.$t('highcharts.downloadPNG'),
                  onclick: function () {
                    this.exportChart({
                      type: 'image/png',
                    });
                  },
                },
              ],
            },
          },
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                chart: {
                  width: null,
                },
              },
            },
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters(['familyStats']),
  },
  watch: {
    familyStats: {
      handler(newVal) {
        console.log('Watcher triggered: `familyStats` updated', newVal); // Log new value
        this.updateChartData(newVal);
      },
      deep: true,
      immediate: true, // Trigger handler immediately on component mount
    },
  },
  methods: {
    ...mapActions(['fetchFamilyStats']),
    getTitle() {
      return (
        this.$tc('category.category', 2).charAt(0).toUpperCase() +
        this.$tc('category.category', 2).slice(1).toLowerCase()
      );
    },
    updateChartData(familyStats) {
      console.log('updateChartData called with:', familyStats); // Log method call with data
      const familyData = familyStats.map((item) => ({
        name: item.familyName,
        y: item.count,
        color: item.color || '#000000', // Use color from database, default to black if not available
      }));
      console.log('Transformed chart data:', familyData); // Log data to be set
      if (this.$refs.highchart) {
        this.$refs.highchart.chart.series[0].setData(familyData, true);
        console.log('Chart updated with:', familyData); // Log successful chart update
      } else {
        console.log('Highchart reference not found'); // Log if chart reference is not found
      }
    },
    resizeChart() {
      if (this.$refs.highchart) {
        this.$refs.highchart.chart.reflow();
      }
    },
  },
  mounted() {
    console.log('Mounted: Fetching family stats'); // Log mounted hook
    this.$store.dispatch('fetchStats', 'fetchCategoriesStats').then(() => {
      this.updateChartData(this.familyStats); // Refresh chart data after fetching
    });
    window.addEventListener('resize', this.resizeChart);
    this.$nextTick(() => {
      this.resizeChart(); // Ensure chart resizes correctly on initial load
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeChart);
  },
};
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
}
</style>
