<template>
  <b-container fluid>
    <b-row v-if="forgot">
      <b-col cols="12">
        <div class="text-center">

          <font-awesome-icon
            :icon="['fal', 'envelope-open']"
            class="fa-3x"
          />
          <br><br>
          <h3>{{ $t('auth.resetEmail.sent') }} {{ this.form.username }}.</h3>
          <h4>{{ $t('auth.resetEmail.followInstruction') }}</h4>
          <br>
          <h4 class="text-info">
            <font-awesome-icon
              :icon="['fal', 'exclamation-triangle']"
              class="fa-lg"
            /> {{ $t('auth.resetEmail.validity') }}
          </h4>
          <br>
          <h4>
            <router-link
              class="link footer-link"
              to="/auth/login"
            >
              {{ $t('auth.login') }}
            </router-link>
          </h4>
        </div>
      </b-col>
    </b-row>

    <b-form
      @submit.prevent="submit"
      v-if="!forgot"
    >

      <div>
        <h3>{{ $t('auth.assistance.title') }}</h3>
        <p>{{ $t('auth.assistance.textEnterEmail') }}</p>
      </div>
      <div>
        <b-form-group>
          <b-form-input
            type="email"
            class="form-control bg-white"
            id="email"
            v-model="form.username"
            :placeholder="$t('auth.email')"
            @blur="$v.form.username.$touch"
          ></b-form-input>
          <label
            class="error"
            v-if="(!$v.form.username.required || !$v.form.username.email) && $v.form.username.$dirty"
          >{{ $t('validate.email') }}</label>
        </b-form-group>
      </div>
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          {{ this.$t('legal.byClicking') }}
          <el-button
            type="text"
            round
            v-b-modal="'modal-TnC'"
            class="p-0"
          >
            {{ this.$t('legal.termsNconditions') }}.
          </el-button>
        </b-col>
      </b-row>
      <b-button
        type="submit"
        variant="primary"
        size="lg"
        block
        class="mb-3"
      >{{ $t('auth.continue') }}</b-button>

      <b-row>
        <b-col cols="8">
          <router-link
            class="link footer-link"
            to="/auth/login"
          >
            {{ $t('auth.login') }}
          </router-link>
        </b-col>
        <b-col
          cols="4"
          class="d-flex justify-content-end"
        >
          <b-dropdown
            id="dropdown"
            variant="link"
            right
          >
            <template #button-content>
              <img
                :src="require(`@/assets/img/flag/flag_${i18n}.svg`)"
                alt="Lauguage flag"
              >
            </template>
            <b-dropdown-item @click.prevent="setLocale('fr')"><img
                :src="require('@/assets/img/flag/flag_fr.svg')"
                alt="French flag"
              >
              <span class="ml-2">{{ $t('navbar.french') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click.prevent="setLocale('en')"><img
                :src="require('@/assets/img/flag/flag_en.svg')"
                alt="English flag"
              >
              <span class="ml-2">{{ $t('navbar.english') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>

    </b-form>
    <modal-TnC></modal-TnC>
  </b-container>

</template>
<script>
import axiosAuth from '@/utils/axiosAuth';
import axiosResource from '@/utils/axiosResource';
import { required, email } from 'vuelidate/lib/validators';
import ModalTnC from '@/components/Modals/ModalTnC.vue';

export default {
  components: {
    ModalTnC,
  },
  data() {
    return {
      form: {
        username: '',
      },
      token: '',
      i18n: this.$root.$i18n.locale,
      forgot: false,
    };
  },
  validations: {
    form: {
      username: {
        required,
        email,
      },
    },
  },
  mounted() {
    this.token = this.$route.query.token;
    // Save token in internal memmory
    localStorage.setItem('gosotoken', this.$route.query.token);
    this.$store.commit('loading', false);
  },
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.submitStatus = 'ERROR';
      } else {
        this.$store.commit('loading', true);
        // do your submit logic here
        console.log(this.token);
        if (typeof this.token === 'undefined' || this.token === '') {
          // The user has forgotten his/her password
          axiosResource
            .post('/emails/forgotpassword', this.form)
            .then((response) => {
              this.$store.commit('loading', false);
              console.log(response.data);
              if (response.data.success === false) {
                this.$globalSwal.error('error.sorry', response.data.msg);
              } else {
                // Hide form and print message
                this.forgot = true;
              }
            })
            .catch((err) => {
              this.$store.commit('loading', false);
              this.$globalSwal.error(err.response.data.msg);
            });
        } else {
          // The user account is not yet activated and the user has received an email with a token
          axiosAuth
            .post('/auth/activation', this.form, {
              headers: {
                Authorization: `Bearer ${this.$route.query.token}`,
              },
            })
            .then((response) => {
              this.$store.commit('loading', false);
              console.log(response.data);
              if (response.data.success === false) {
                this.$globalSwal.error('error.sorry', response.data.msg);
              } else {
                // GO to password setup
                this.$router.push(
                  `/auth/passwordsetup?token=${this.$route.query.token}`
                );
              }
            })
            .catch((err) => {
              this.$store.commit('loading', false);
              this.$globalSwal.error(err.response.data.msg);
            });
        }

        this.submitStatus = 'PENDING';
        setTimeout(() => {
          this.submitStatus = 'OK';
        }, 500);
      }
    },
    setLocale(locale) {
      // Set i18n locale global variable
      this.$i18n.locale = locale;
      // Store locale in localstorage
      localStorage.setItem('lang', locale);
      console.log(`changed locale to ${locale}`);
      this.i18n = locale;
    },
  },
};
</script>
<style scoped>
.nav-link-auth {
  padding: 0rem 0rem;
}
.auth-form {
  max-width: 330px;
}
</style>
