<template>
  <b-container fluid>

    <b-form @submit.prevent="submit">

      <div>
        <h3>{{ $t('auth.assistance.title') }}</h3>
        <p>{{ $t('auth.assistance.textChoosePassword') }}</p>

      </div>
      <div>
        <b-form-group>
          <b-form-input
            type="password"
            class="form-control bg-white"
            id="password"
            v-model="form.password"
            :placeholder="$t('auth.password')"
            @blur="$v.form.password.$touch"
          ></b-form-input>
          <label
            class="error"
            v-if="!$v.form.password.required && $v.form.password.$dirty"
          >{{ $t('validate.required') }}</label>
        </b-form-group>

        <p class="password-instruction-title">
          Password must meet the following requirements:
        </p>
        <p
          class="password-instruction right"
          v-if="containsLetter(this.form.password) && form.password.length > 0"
        >
          <font-awesome-icon icon="fa-solid fa-check" />
          At least one letter
        </p>
        <p
          class="password-instruction wrong"
          v-else
        >
          <font-awesome-icon icon="fa-solid fa-xmark" />
          At least one letter
        </p>
        <p
          class="password-instruction right"
          v-if="containsUpper(this.form.password) && form.password.length > 0"
        >
          <font-awesome-icon icon="fa-solid fa-check" />
          At least one capital letter
        </p>
        <p
          class="password-instruction wrong"
          v-else
        >
          <font-awesome-icon icon="fa-solid fa-xmark" />
          At least one capital letter
        </p>

        <p
          class="password-instruction right"
          v-if="containsNumber(this.form.password) && form.password.length > 0"
        >
          <font-awesome-icon icon="fa-solid fa-check" />
          At least one number
        </p>
        <p
          class="password-instruction wrong"
          v-else
        >
          <font-awesome-icon icon="fa-solid fa-xmark" />
          At least one number
        </p>
        <p
          class="password-instruction right"
          v-if="form.password.length >= 8"
        >
          <font-awesome-icon icon="fa-solid fa-check" />
          Be at least 8 characters
        </p>
        <p
          class="password-instruction wrong"
          v-else
        >
          <font-awesome-icon icon="fa-solid fa-xmark" />
          Be at least 8 characters
        </p>
        <br>
        <b-form-group>
          <b-form-input
            type="password"
            class="form-control bg-white"
            id="passwordConfirm"
            v-model="form.passwordConfirm"
            :placeholder="$t('auth.passwordConfirm')"
            @blur="$v.form.passwordConfirm.$touch"
          ></b-form-input>
          <label
            class="error"
            v-if="!$v.form.passwordConfirm.required && $v.form.passwordConfirm.$dirty"
          >{{ $t('validate.required') }}</label>
          <label
            class="error"
            v-if="!$v.form.passwordConfirm.sameAs && $v.form.passwordConfirm.$dirty"
          >{{ $t('auth.sameAs') }}</label>
        </b-form-group>
      </div>

      <b-button
        type="submit"
        variant="primary"
        size="lg"
        block
        class="mb-3"
      >{{ $t('auth.continue') }}</b-button>

      <b-row>
        <b-col cols="8">
          <router-link
            class="link footer-link"
            to="/auth/login"
          >
            {{ $t('auth.login') }}
          </router-link>
        </b-col>
        <b-col
          cols="4"
          class="d-flex justify-content-end"
        >
          <b-dropdown
            id="dropdown"
            variant="link"
            right
          >
            <template #button-content>
              <img
                :src="require(`@/assets/img/flag/flag_${i18n}.svg`)"
                alt="Lauguage flag"
              >
            </template>
            <b-dropdown-item @click.prevent="setLocale('fr')"><img
                :src="require('@/assets/img/flag/flag_fr.svg')"
                alt="French flag"
              >
              <span class="ml-2">{{ $t('navbar.french') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click.prevent="setLocale('en')"><img
                :src="require('@/assets/img/flag/flag_en.svg')"
                alt="English flag"
              >
              <span class="ml-2">{{ $t('navbar.english') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>

    </b-form>

  </b-container>
</template>
<script>
import axiosAuth from '@/utils/axiosAuth';
import { required, sameAs } from 'vuelidate/lib/validators';
import swal from 'sweetalert2';

export default {
  data() {
    return {
      form: {
        password: '',
        passwordConfirm: '',
      },
      token: '',
      i18n: this.$root.$i18n.locale,
    };
  },
  validations: {
    form: {
      password: {
        required,
      },
      passwordConfirm: {
        required,
        sameAs: sameAs('password'),
      },
    },
  },
  mounted() {
    console.log(this.i18n);
    this.token = localStorage.getItem('gosotoken');
    console.log(`Bearer ${this.$route.query.token}`);
  },
  methods: {
    containsLetter(str) {
      return /[a-zA-Z]/.test(str);
    },
    containsUpper(str) {
      return /[A-Z]/.test(str);
    },
    containsNumber(str) {
      return /[0-9]/.test(str);
    },
    submit() {
      this.$v.form.$touch();
      if (this.$v.$invalid) {
        console.log('ERROR');
        this.submitStatus = 'ERROR';
      } else {
        // do your submit logic here
        // Change password
        axiosAuth
          .post('/auth/changepassword', this.form, {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          })
          .then((response) => {
            this.$store.commit('loading', false);
            console.log(response.data);
            if (response.data.success == false) {
              console.log('HERE', response.data);
              this.$globalSwal.error(response.data.msg);
            } else {
              swal
                .fire({
                  title: this.$i18n.t('auth.activated'),
                  text: this.$i18n.t('auth.loginToStart'),
                  icon: 'success',
                  confirmButtonText: 'OK',
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    this.$router.push('/auth/login');
                  }
                });
            }
          })
          .catch((err) => {
            this.$store.commit('loading', false);
            this.$globalSwal.error(err.response.data.msg);
          });
        // this.$router.push("/settings/userregister");
      }
    },
    setLocale(locale) {
      // Set i18n locale global variable
      this.$i18n.locale = locale;
      // Store locale in localstorage
      localStorage.setItem('lang', locale);
      console.log(`changed locale to ${locale}`);
      this.i18n = locale;
    },
  },
};
</script>
<style>
.nav-link-auth {
  padding: 0rem 0rem;
}
.auth-form {
  max-width: 330px;
}
</style>
<style scoped>
.password-instruction-title {
  margin-bottom: 0.6rem;
}
.password-instruction {
  margin-bottom: 0;
  margin-left: 1rem;
}
.right {
  color: #30D158;
  font-weight: 800;
}
.wrong {
  color: #FF375F;
  font-weight: 800;
}
</style>
