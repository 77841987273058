<template>
  <div class="progress-block">
    <font-awesome-icon
      class="icon"
      icon="fa-thin fa-file-lines"
    />
    <b-progress-bar
      :value="progress"
      variant="success"
      class="progress-bar"
      animated
    ></b-progress-bar>
    <font-awesome-icon
      class="stop-upload"
      icon="fa-thin fa-xmark"
      @click.prevent="$emit('stopUpload')"
    />
    <!-- <el-progress :percentage="50"></el-progress> -->
    <!-- <SPAN>SONE TEXT</SPAN> -->
  </div>
</template>

<script>
export default {
  name: 'ImageBlock',
  props: ['progress'],
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
.progress-block {
  width: 100%;
  height: 44px;
  row-gap: 8px;
  margin: 12px 0;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  background-color: #fff;
  transition: 0.3s ease all;
  overflow: hidden;
  z-index: 0;
  padding: 4px 4px 10px 90px;
  display: flex;

  .icon {
    vertical-align: middle;
    display: inline-block;
    width: 70px;
    height: 22px;
    position: relative;
    z-index: 1;
    margin-left: -80px;
    margin-top: 6px;
    background-color: #fff;
  }

  .progress-bar {
    background-color: white;
    width: 100%;
    height: 4px;
    margin: 16px 20px;
  }

  .stop-upload {
    position: absolute;
    right: 22px;
    cursor: pointer;
  }
}
</style>