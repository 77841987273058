const zones = [
  {
    value: 'A',
    label: 'A',
  },
  {
    value: 'B',
    label: 'B',
  },
  {
    value: 'C',
    label: 'C',
  },
  {
    value: 'D',
    label: 'D',
  },
  {
    value: 'E',
    label: 'E',
  },
  {
    value: 'F',
    label: 'F',
  },
  {
    value: 'G',
    label: 'G',
  },
  {
    value: 'H',
    label: 'H',
  },
  {
    value: 'I',
    label: 'I',
  },
  {
    value: 'J',
    label: 'J',
  },
  {
    value: 'K',
    label: 'K',
  },
  {
    value: 'L',
    label: 'L',
  },
  {
    value: 'M',
    label: 'M',
  },
  {
    value: 'N',
    label: 'N',
  },
  {
    value: 'O',
    label: 'O',
  },
  {
    value: 'P',
    label: 'P',
  },
  {
    value: 'Q',
    label: 'Q',
  },
  {
    value: 'R',
    label: 'R',
  },
  {
    value: 'S',
    label: 'S',
  },
  {
    value: 'T',
    label: 'T',
  },
  {
    value: 'U',
    label: 'U',
  },
  {
    value: 'V',
    label: 'V',
  },
  {
    value: 'W',
    label: 'W',
  },
  {
    value: 'X',
    label: 'X',
  },
  {
    value: 'Y',
    label: 'Y',
  },
  {
    value: 'Z',
    label: 'Z',
  },
];
export default zones;
