<template>
  <div>
    <side-bar-menu :menu="$section().home"></side-bar-menu>
    <idle-timer></idle-timer>
    <b-row class="border-bottom d-md-flex align-items-center pl-3 header-row">
      <b-col>
        <h2>{{ $t('settings.title') }}</h2>
      </b-col>
    </b-row>
    <b-row class="pl-3 d-md-flex">
      <b-col
        cols="12"
        md="12"
        class="mt-2 mb-4 pb-4 border-bottom bg-white"
      >

        <!-- MY PROFILE -->

        <div v-if="userDetails.username !== ''">
          <h3 class="mt-2 mb-4">{{ $t('settings.myProfile') }}</h3>
          <div class="ml-2">
            <p>
              <font-awesome-icon
                icon="fa-light fa-user"
                size="xl"
                class="ml-2 mr-3 fa-fw"
              />
              {{ userDetails.lastname }} {{ userDetails.firstname }}
              <el-tag
                class="ml-2"
                :type="userDetails.type"
              >{{ userDetails.nature }}</el-tag>
            </p>
            <p>
              <font-awesome-icon
                icon="fa-light fa-at"
                size="xl"
                class="ml-2 mr-3 fa-fw"
              />
              <a href="mailto:contact@goso.cloud">{{ userDetails.username }}</a>
            </p>
            <p>
              <font-awesome-icon
                icon="fa-light fa-phone"
                size="xl"
                class="ml-2 mr-3 fa-fw"
              />{{ userDetails.phone }}
            </p>
            <p>
              <font-awesome-icon
                icon="fa-light fa-building"
                size="xl"
                class="ml-2 mr-3 fa-fw"
              />{{ userDetails.entity.name }} ({{ userDetails.entity.shortname }})
            </p>
            <p>
              <font-awesome-icon
                icon="fa-light fa-clock"
                size="xl"
                class="ml-2 mr-3 fa-fw"
              />Since {{ userDetails.since }}
            </p>
          </div>
        </div>

        <!-- NOTIFICATIONS -->

        <h3 class="mt-5 mb-4">{{ $t('settings.notifications') }}</h3>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <div class="ml-2 table-responsive-sm">
              <table class="table">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="col-8"
                    ></th>
                    <th
                      scope="col"
                      class="col-2"
                    >IN-APP</th>
                    <th
                      scope="col"
                      class="col-2"
                    >EMAIL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>New report created: LONG-TERM</td>
                    <td><el-switch
                        v-model="notification.app.priority.longterm"
                        disabled
                      ></el-switch></td>
                    <td><el-switch
                        v-model="notification.email.priority.longterm"
                        disabled
                      ></el-switch></td>
                  </tr>
                  <tr>
                    <td>New report created: MEDIUM-TERM</td>
                    <td><el-switch
                        v-model="notification.app.priority.mediumterm"
                        disabled
                      ></el-switch></td>
                    <td><el-switch
                        v-model="notification.email.priority.mediumterm"
                        disabled
                      ></el-switch></td>
                  </tr>
                  <tr>
                    <td>New report created: SHORT-TERM</td>
                    <td><el-switch
                        v-model="notification.app.priority.shortterm"
                        disabled
                      ></el-switch></td>
                    <td><el-switch
                        v-model="notification.email.priority.shortterm"
                        disabled
                      ></el-switch></td>
                  </tr>
                  <tr>
                    <td>New report created: URGENT</td>
                    <td><el-switch
                        v-model="notification.email.priority.urgent"
                        disabled
                      ></el-switch></td>
                    <td><el-switch
                        v-model="notification.app.priority.urgent"
                        disabled
                      ></el-switch></td>
                  </tr>
                  <tr>
                    <td>Report SOLVED</td>
                    <td><el-switch
                        v-model="notification.email.priority.urgent"
                        disabled
                      ></el-switch></td>
                    <td><el-switch
                        v-model="notification.app.priority.urgent"
                        disabled
                      ></el-switch></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>

        <!-- MY FAVORITES -->

        <h3 class="mt-4 mb-4">{{ $t('settings.myFavorite') }}</h3>
        <div class="ml-2">

          <!-- Description -->
          <b-row>
            <b-col
              cols="8"
              md="5"
            >
              <h4>{{ $t('create.description.free').toUpperCase() }}</h4>
            </b-col>
            <b-col
              cols="4"
              md="2"
            >
              <el-button
                type="primary"
                size="mini"
                round
                @click="openModal('description', '', 'new')"
              >{{ $t('action.add') }}</el-button>
            </b-col>
          </b-row>

          <span
            class="info-colo"
            v-if="favorites.descriptions.length == 0"
          >{{ $t('favorite.none') }}</span>

          <ul>
            <li
              v-for="description in favorites.descriptions"
              :key="description"
            >
              {{ description }}
              <el-button
                class="ml-2"
                type="text"
                @click="openModal('description', description, 'edit')"
              ><small>{{ $t('action.edit').toUpperCase() }}</small></el-button>
              <el-button
                class="ml-2"
                type="text"
                @click="confirmRemoveFavorite('description', description)"
              ><small>{{ $t('action.delete').toUpperCase() }}</small></el-button>
            </li>
          </ul>

          <!-- Standards -->
          <b-row>
            <b-col
              cols="8"
              md="5"
            >
              <h4>{{ $t('create.description.standard').toUpperCase() }}</h4>
            </b-col>
            <b-col
              cols="4"
              md="2"
            >
              <el-button
                type="primary"
                size="mini"
                round
                @click="openModal('standard', '', 'new')"
              >{{ $t('action.add') }}</el-button>
            </b-col>
          </b-row>
          <span
            class="info-colo"
            v-if="favorites.standards.length == 0"
          >{{ $t('favorite.none') }}</span>
          <ul>
            <li
              v-for="standard in favorites.standards"
              :key="standard"
            >
              {{ standard }}
              <el-button
                class="ml-2"
                type="text"
                @click="openModal('standard', standard, 'edit')"
              ><small>{{ $t('action.edit').toUpperCase() }}</small></el-button>
              <el-button
                class="ml-2"
                type="text"
                @click="confirmRemoveFavorite('standard', standard)"
              ><small>{{ $t('action.delete').toUpperCase() }}</small></el-button>
            </li>
          </ul>

          <!-- Corrective action -->
          <b-row>
            <b-col
              cols="8"
              md="5"
            >
              <h4>{{ $t('create.description.correctiveAction').toUpperCase() }}</h4>
            </b-col>
            <b-col
              cols="4"
              md="2"
            >
              <el-button
                type="primary"
                size="mini"
                round
                @click="openModal('correctiveAction', '', 'new')"
              ><small>{{ $t('action.add') }}</small></el-button>
            </b-col>
          </b-row>
          <span
            class="info-colo"
            v-if="favorites.correctiveActions.length == 0"
          >{{ $t('favorite.none') }}</span>
          <ul>
            <li
              v-for="correctiveAction in favorites.correctiveActions"
              :key="correctiveAction"
            >
              {{ correctiveAction }}
              <el-button
                class="ml-2"
                type="text"
                @click="openModal('correctiveAction', correctiveAction, 'edit')"
              ><small>{{ $t('action.edit').toUpperCase() }}</small></el-button>
              <el-button
                class="ml-2"
                type="text"
                @click="confirmRemoveFavorite('correctiveAction', correctiveAction)"
              ><small>{{ $t('action.delete').toUpperCase() }}</small></el-button>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
    <modal-favorite
      :type="type"
      :value="value"
      :scope="scope"
      @favorite-saved="fetchFavorites"
    ></modal-favorite>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import IdleTimer from '../../components/IdleTimer.vue';
import SideBarMenu from '../../components/SideBarMenu.vue';
import ModalFavorite from '@/components/Modals/ModalFavorite.vue';
import axiosResource from '@/utils/axiosResource';
import swal from 'sweetalert2';
const swalBootsrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    denyButton: 'btn btn-info btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export default {
  components: {
    SideBarMenu,
    IdleTimer,
    ModalFavorite,
  },
  data() {
    return {
      show: false,
      projectRef: this.$store.getters.currentProject.ref,
      siteFullTemp: '',
      userDetails: {
        username: '',
        firstname: '',
        lastname: '',
        nature: '',
        type: '',
        phone: '',
        entity: '',
        since: '',
      },
      notification: {
        app: {
          priority: {
            longterm: false,
            mediumterm: false,
            shortterm: false,
            urgent: false,
          },
        },
        email: {
          priority: {
            longterm: false,
            mediumterm: false,
            shortterm: false,
            urgent: false,
          },
        },
      },
      type: '',
      value: '',
      scope: '',
      favorites: {
        descriptions: [],
        standards: [],
        correctiveActions: [],
      },
    };
  },
  methods: {
    ...mapMutations(['SAVE_CONTEXT']),
    openModal(type, value, scope) {
      this.type = type;
      this.value = value;
      this.scope = scope;
      this.$nextTick(() => {
        this.$bvModal.show('modal-favorite');
      });
    },
    fetchFavorites() {
      axiosResource
        .get('/favorites/')
        .then((response) => {
          this.favorites.descriptions = response.data.results.descriptions;
          this.favorites.standards = response.data.results.standards;
          this.favorites.correctiveActions =
            response.data.results.correctiveActions;
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    confirmRemoveFavorite(type, value) {
      swalBootsrap
        .fire({
          title: this.$t('favorite.deleteTitle'),
          text: this.$t('favorite.deleteText'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('deleteConfirm'),
          cancelButtonText: this.$t('deleteCancel'),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.removeFavorite(type, value);
          }
        });
    },
    removeFavorite(type, value) {
      axiosResource
        .delete(`/favorites/${type}`, { data: { value } })
        .then((response) => {
          if (response.data.success) {
            this.$message({
              message: this.$t('favorite.removed'),
              type: 'info',
            });
            this.fetchFavorites();
          }
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    loadUser() {
      const userUuid = this.$store.getters.loggedUser.uuid;
      axiosResource
        .get('/admin/users/' + userUuid)
        .then((response) => {
          console.log(response);
          this.userDetails.firstname = response.data.user.name.firstname;
          this.userDetails.lastname = response.data.user.name.lastname;
          this.userDetails.username = response.data.user.username;
          this.userDetails.phone = response.data.user.phone;
          this.userDetails.nature = response.data.user.nature;
          this.userDetails.entity = response.data.user.entity;
          this.userDetails.since = response.data.user.dateCreated;
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
  },
  mounted() {
    this.show = !this.show;
    this.SAVE_CONTEXT(this.$route.path);
    this.loadUser();
    this.fetchFavorites();
    document.getElementById('navbar').classList.add('navbar-shadow');
  },
};
</script>

