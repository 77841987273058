<template>
  <b-row>
    <b-col
      cols="12"
      lg="6"
    >
      <div>
        <b-form-group
          id="input-group-interventionType"
          :label="$t('create.text.interventionType')"
          label-for="interventionType"
        >
          <el-select
            v-model="typeSelected"
            placeholder="Select"
            v-if="purpose == 'detailed'"
          >
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>

          <!-- QUICK: like -->

          <div v-if="purpose == 'like'">
            <font-awesome-icon
              icon="fa-light fa-thumbs-up"
              size="2x"
              class="quick-like"
            />
            <span class="quick-text quick-like">{{ $t('create.text.quickLike') }}</span>
          </div>

          <!-- QUICK: visited -->

          <div v-if="purpose == 'visited'">
            <font-awesome-icon
              icon="fa-light fa-person-walking"
              size="2x"
              class="quick-visited"
            />
            <span class="quick-text quick-visited">{{ $t('create.text.quickVisited') }}</span>
          </div>

          <!-- QUICK: locked -->

          <div v-if="purpose == 'locked'">
            <font-awesome-icon
              icon="fa-light fa-lock"
              size="2x"
              class="quick-locked"
            />
            <span class="quick-text quick-locked">{{ $t('create.text.quickLocked') }}</span>
          </div>

          <!-- QUICK: busy -->

          <div v-if="purpose == 'busy'">
            <font-awesome-icon
              icon="fa-light fa-ban"
              size="2x"
              class="quick-busy"
            />
            <span class="quick-text quick-busy">{{ $t('create.text.quickBusy') }}</span>
          </div>

        </b-form-group>
      </div>
    </b-col>
    <b-col
      cols="12"
      lg="6"
      class="mt-2 mt-md-0"
    >
      <b-form-group
        id="input-group-interventionDate"
        :label="$t('create.text.interventionDate')"
        label-for="interventionDate"
        :description="$t('create.text.interventionDateCaption')"
      >
        <el-date-picker
          v-model="interventionDate"
          type="date"
          :placeholder="$t('create.selectDate')"
          @change="emitInterventionDateUpdate"
        >
        </el-date-picker>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import moment from 'moment';

export default {
  name: 'general',
  props: ['purpose', 'report', 'mode'],
  data() {
    return {
      typeSelected: 'IS',
      typeOptions: [
        { label: 'IS - Inspection systématique', value: 'IS' },
        { label: 'INS - Inspection non systématique', value: 'INS' },
        { label: 'FC - Fiche constat', value: 'FC' },
        { label: 'ST - Suivi de travaux', value: 'ST' },
      ],
      interventionDate: '',
    };
  },
  watch: {
    report: {
      immediate: true,
      handler(newReport) {
        if (this.mode === 'edit' && newReport) {
          this.typeSelected = newReport.interventionType || 'IS';
          this.interventionDate =
            newReport.interventionDate || moment().format('YYYY-MM-DD');
        }
      },
    },
  },
  methods: {
    emitInterventionDateUpdate() {
      console.log('emitInterventionDateUpdate');
      this.$emit('intervention-date-updated', this.interventionDate);
    },
  },
  mounted() {
    if (this.mode === 'edit' && this.report) {
      this.typeSelected = this.report.interventionType || 'IS';
      this.interventionDate =
        this.report.interventionDate || moment().format('YYYY-MM-DD');
    } else {
      this.interventionDate = moment().format('YYYY-MM-DD');
    }
  },
};
</script>

<style scoped>
.el-select {
  width: 100%;
}
.el-date-editor.el-input {
  width: 100% !important;
}
.quick-text {
  margin-left: 12px;
  font-weight: 200;
  font-size: 22px;
}
</style>
