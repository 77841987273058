<template>
  <div>
    <div
      class="map-block"
      v-loading="loading"
    >
      <label></label>
      <p id="first"><i class="el-icon-check"></i></p>
      <font-awesome-icon
        class="icon"
        icon="fa-sharp fa-solid fa-location-dot"
      />
      <div id="canvas-container-block">
        <canvas id="pdf-renderer-block"></canvas>
      </div>
      <span
        id="edit"
        @click="$emit('openDialog')"
      ><i class="el-icon-edit mr-2"></i> EDIT</span>
    </div>
  </div>
</template>

<script>
import pdfjsLib from 'pdfjs-dist/build/pdf';
import 'pdfjs-dist/web/pdf_viewer.css';
pdfjsLib.GlobalWorkerOptions.workerSrc =
  '../../node_modules/pdfjs-dist/build/pdf.worker.js';

export default {
  name: 'map-block',
  props: ['fileName', 'x', 'y'],
  data() {
    return {
      loading: true,
      pdf: null,
      scale: 2,
      canvas: {
        width: null,
        height: null,
      },
    };
  },
  mounted() {
    const clgStyle = 'color: red;';
    console.log('%c' + 'MapBlock MOUNTED', clgStyle);
    console.log('this.filename= ' + this.fileName);
    this.getDocument();
  },
  methods: {
    async getDocument() {
      console.log('this.filename in getDocument= ' + this.fileName);
      const url = this.fileName;
      var loadingTask = pdfjsLib.getDocument(url);
      loadingTask.promise.then((pdf) => {
        this.pdf = pdf;
        this.render();
      });
    },
    render() {
      this.loading = true;
      const pdf = this.pdf;
      pdf.getPage(1).then((page) => {
        // let vm = this;
        let canvas = document.getElementById('pdf-renderer-block');
        let ctx = canvas.getContext('2d');
        const scale = this.scale;

        // canvas.style.top = -50 + 'px';
        // canvas.style.left = -50 + 'px';

        let viewport = page.getViewport({ scale });

        canvas.width = viewport.width;
        canvas.height = viewport.height;
        this.canvas.width = viewport.width;
        this.canvas.height = viewport.height;

        const renderContext = {
          canvasContext: ctx,
          viewport,
        };
        console.log(
          `viewport.width: ${viewport.width}, viewport.height: ${viewport.height}`
        );

        // Render PDF
        page.render(renderContext).then(() => {
          this.loading = false;
          this.scrollTo();
        });
      });
    },
    scrollTo() {
      const clgStyle = 'color: magenta; font-size: 20px;;';
      console.log('%c' + ' SCROLLTO', clgStyle);
      console.log(
        `x%:${this.x} y%:${this.y} canvas.width:${this.canvas.width} canvas.height:${this.canvas.height}`
      );
      const canvasContainerBlock = document.getElementById(
        'canvas-container-block'
      );
      console.log(canvasContainerBlock.clientWidth);
      console.log(canvasContainerBlock.clientHeight);

      const scrollToX = Math.ceil(
        (this.x / 100) * this.canvas.width -
          canvasContainerBlock.clientWidth / 2
      );
      const scrollToY = Math.ceil(
        (this.y / 100) * this.canvas.height -
          canvasContainerBlock.clientHeight / 2
      );

      const container = document.getElementById('canvas-container-block');
      container.scrollTo(scrollToX, scrollToY, 'instant');
      // alert(
      //   `scrollToX=${scrollToX}, scrollToY=${scrollToY} +++++ canvasWidth=${this.canvas.width}, ${canvasContainerBlock.clientWidth}, canvasHeight=${this.canvas.height}, ${canvasContainerBlock.clientHeight}`
      // );
    },
  },
};
</script>


<style scoped lang="scss">
#canvas-container-block {
  width: 100% !important;
  height: 260px !important;
  overflow: hidden !important;
}
.map-block {
  width: 100%;
  height: 260px;
  row-gap: 8px;
  margin: 12px 0;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  background-color: #fff;
  transition: 0.3s ease all;
  overflow: hidden;
  z-index: 0;
  position: relative;

  label {
    position: absolute;
    display: block;
    right: -27px;
    top: -9px;
    width: 60px;
    height: 36px;
    background: #13ce66;
    text-align: center;
    transform: rotate(45deg);
    box-shadow: 0 1px 1px #ccc;
  }

  #first {
    position: absolute;
    right: 3px;
    top: 3px;
    color: white;
    z-index: 1;
    margin: 0;
    padding: 0;
  }

  .icon {
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 15px);
    color: #ff453a;
    font-size: 40px;
    margin: auto;
  }

  #edit {
    position: absolute;
    color: red;
    font-size: 1rem;
    font-weight: 200;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 100px;
    cursor: pointer;
    background-color: white;
    bottom: 0px;
    right: 0px;
    border: 1px solid red;
    margin: 6px;
  }

  #edit:hover {
    font-weight: 800;
  }

  //   canvas {
  //     position: inherit;
  //     width: 100%;
  //     height: 180px;
  //   }
}
</style>
