<template>
  <div class="d-flex justify-content-end">
    <div
      :class="`status-${status} status-${target}`"
      class="status-container"
    >{{ $t('status.'+status) }}</div>
  </div>
</template>
<script>
export default {
  name: 'status',
  props: ['status', 'target'],
  date() {
    return {};
  },
};
</script>
<style scoped>
.status-container {
  display: inline-flex; /* Use inline-flex for proper alignment */
  border-radius: 4px; /* Optional: Add border radius for better appearance */
  cursor: auto;
}
</style>