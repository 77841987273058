<template>
  <div class="chart-container">
    <!-- Show a loading message while waiting for data -->
    <div v-if="loading">
      <p>Loading chart data...</p>
    </div>

    <!-- Render the chart only when data is available -->
    <highcharts
      v-if="!loading && chartDataAvailable"
      :options="chartOptions"
      ref="highcharts"
    ></highcharts>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';

export default {
  name: 'status',
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      loading: true, // Initial loading state
      chartDataAvailable: false, // Control when to mount the chart
      chartOptions: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          height: '100%',
        },
        credits: {
          enabled: false,
        },
        title: {
          text: 'Status',
          align: 'left',
          style: { fontSize: '16px' },
        },
        subtitle: {
          text: this.$t('highcharts.overPeriod'),
          align: 'left',
          style: { fontSize: '10px' },
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
        },
        accessibility: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              formatter: function () {
                return (
                  '<span style="font-size: smaller;">' +
                  this.point.name +
                  '</span><br/>' +
                  '<span style="color: gray; font-weight: normal;">' +
                  this.y +
                  ' (' +
                  Math.round(this.percentage * 10) / 10 +
                  '%)</span>'
                );
              },
              useHTML: true,
            },
            showInLegend: false,
          },
        },
        series: [
          {
            name: 'Status',
            colorByPoint: true,
            data: [], // Initially empty
          },
        ],
      },
    };
  },
  computed: {
    // Get the current status data from Vuex
    statusData() {
      return this.$store.getters.chart.status;
    },
  },
  watch: {
    // Watch the status data and update the chart when it changes
    statusData: {
      handler(newStatus) {
        if (newStatus && typeof newStatus.OPEN !== 'undefined') {
          this.updateChartData(newStatus); // Update chart data when status changes
        }
      },
      immediate: true, // Run the watcher immediately when the component is mounted
      deep: true, // Ensure deep watching for nested changes
    },
  },
  methods: {
    updateChartData(status) {
      const data = [
        {
          name: this.$t('status.OPEN'),
          y: Number(status.OPEN),
          color: 'rgba(227, 159, 167, 0.9)',
        },
        {
          name: this.$t('status.ADDRESSED'),
          y: Number(status.ADDRESSED),
          color: 'rgba(129, 223, 138, 0.9)',
        },
        {
          name: this.$t('status.ONGOING'),
          y: Number(status.ONGOING),
          color: 'rgba(103, 182, 110, 0.9)',
        },
        {
          name: this.$t('status.SOLVED'),
          y: Number(status.SOLVED),
          color: 'rgba(235, 218, 141, 0.9)',
        },
        {
          name: this.$t('status.CLOSED'),
          y: Number(status.CLOSED),
          color: 'rgba(106, 212, 228, 0.9)',
        },
        {
          name: this.$t('status.ONHOLD'),
          y: Number(status.ONHOLD),
          color: 'rgba(129, 148, 240, 0.9)',
        },
        {
          name: this.$t('status.NOACTION'),
          y: Number(status.NOACTION),
          color: 'rgba(140, 140, 140, 0.9)',
        },
        {
          name: this.$t('status.DELETED'),
          y: Number(status.DELETED),
          color: 'rgba(183, 183, 183, 0.9)',
        },
      ];

      // Update chart data and refresh the view
      this.chartOptions.series[0].data = data;
      this.chartDataAvailable = true; // Data is now available
      this.loading = false; // Stop loading once data is ready

      // Resize the chart to fit the container
      this.$nextTick(() => {
        this.resizeChart();
      });
    },
    resizeChart() {
      if (this.$refs.highcharts && this.$refs.highcharts.chart) {
        this.$refs.highcharts.chart.reflow();
      }
    },
  },
  mounted() {
    // Fetch the initial data from Vuex
    this.$store.dispatch('fetchStats', 'status');

    window.addEventListener('resize', this.resizeChart);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeChart);
  },
};
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
}
</style>
