<template>
  <div>
    <side-bar-menu :menu="$section().home"></side-bar-menu>
    <idle-timer></idle-timer>
    <b-row class="border-bottom d-md-flex align-items-center ml-2 header-row">
      <b-col>
        <h2>{{ $t('support.title') }}</h2>
      </b-col>

    </b-row>
    <div class="ml-2">

      <b-row class="pl-3 mb-2 mt-3">
        <b-col
          cols="12"
          md="6"
        >
          <h3>{{ $t('support.training.videoTraining') }}</h3>
        </b-col>
      </b-row>

      <b-row class="pl-3 mb-2 mt-3">
        <!-- VIDEO: THEORY Interaction -->
        <b-col
          cols="12"
          md="6"
        >
          <h4>{{ $t('support.training.interactions.title') }}</h4>
          <p>{{ $t('support.training.interactions.text') }}</p>
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              src="https://www.youtube.com/embed/CuiYEGzpL7Q?origin=https://goso.1sameorigin.com&rel=0&modestbranding=1"
              title="YouTube video player"
              frameborder="0"
              allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </b-col>
        <!-- VIDEO: THEORY Intervention result -->
        <b-col
          cols="12"
          md="6"
        >
          <h4>{{ $t('support.training.interventionResult.title') }}</h4>
          <p>{{ $t('support.training.interventionResult.text') }}</p>
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              src="https://www.youtube.com/embed/OPvdUvnYMAg?origin=https://goso.1sameorigin.com&rel=0&modestbranding=1"
              title="YouTube video player"
              frameborder="0"
              allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </b-col>

      </b-row>
      <b-row class="pl-3 mb-2 mt-3">
        <!-- VIDEO: THEORY Status and Flags -->
        <b-col
          cols="12"
          md="6"
        >
          <h4>{{ $t('support.training.status.title') }}</h4>
          <p>{{ $t('support.training.status.text') }}</p>
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              src="https://www.youtube.com/embed/RAZGfNkCRBg?origin=https://goso.1sameorigin.com&rel=0&modestbranding=1"
              title="YouTube video player"
              frameborder="0"
              allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </b-col>
        <!-- VIDEO: THEORY Nature & Admin privileges -->
        <b-col
          cols="12"
          md="6"
        >
          <h4>{{ $t('support.training.natureAdmin.title') }}</h4>
          <p>{{ $t('support.training.natureAdmin.text') }}</p>
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              src="https://www.youtube.com/embed/WsQX8WEXblA?origin=https://goso.1sameorigin.com&rel=0&modestbranding=1"
              :title="$t('support.training.natureAdmin.title')"
              frameborder="0"
              allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </b-col>

      </b-row>

      <!-- Contacts -->
      <b-row class="pl-3 mb-2 mt-5">
        <b-col>
          <h3 class="mb-3">{{ $t('support.bug.title') }}</h3>
          <p>{{ $t('support.bug.text') }}</p>
          <p>
            <font-awesome-icon
              icon="fa-light fa-at"
              size="xl"
              class="ml-2 mr-3 fa-fw"
            />
            <a href="mailto:contact@goso.cloud">alexandre.geiss@hotmail.com</a>
          </p>
          <p>
            <font-awesome-icon
              icon="fa-light fa-at"
              size="xl"
              class="ml-2 mr-3 fa-fw"
            />
            <a href="mailto:contact@goso.cloud">contact@goso.cloud</a>
          </p>
          <p>
            <font-awesome-icon
              icon="fa-light fa-phone"
              size="xl"
              class="ml-2 mr-3 fa-fw"
            /> +33.670.19.19,17
          </p>
          <p>
            <font-awesome-icon
              icon="fa-light fa-messages"
              size="xl"
              class="ml-2 mr-3 fa-fw"
            /> +33.670.19.19,17 (SMS, WhatsApp, Signal)
          </p>
        </b-col>
      </b-row>
      <!-- Contact US -->
      <b-row class="pl-3 mb-2 mt-5">
        <b-col>
          <h3 class="mb-3">{{ $t('support.contactUs.title') }}</h3>
          <p>
            <font-awesome-icon
              icon="fa-light fa-clock"
              size="xl"
              class="ml-2 mr-3 fa-fw"
            />{{ $t('support.contact.hours') }}

          </p>
          <p>
            <font-awesome-icon
              icon="fa-light fa-at"
              size="xl"
              class="ml-2 mr-3 fa-fw"
            />
            <a href="mailto:contact@goso.cloud">contact@goso.cloud</a>
          </p>
          <p>
            <font-awesome-icon
              icon="fa-light fa-phone"
              size="xl"
              class="ml-2 mr-3 fa-fw"
            /> +33.670.19.19,17
          </p>

          <p>
            <font-awesome-icon
              icon="fa-light fa-home-blank"
              size="xl"
              class="ml-2 mr-3 fa-fw"
            /> <b>APP ENGINEERING DESIGN (A.E.D)</b>
            <br><span class="company-details">
              <small>SIRET 840.392.013.00016</small>
            </span><br>
            <span class="company-details">
              4, rue Fédéric Chopin
            </span><br>
            <span class="company-details">
              88130 Charmes
            </span><br>
            <span class="company-details">
              FRANCE
            </span>
          </p>

        </b-col>
      </b-row>
    </div>

    <!-- SAFETY MARGIN -->
    <div class="safety-margin"></div>
  </div>
</template>






<script>
import { mapMutations } from 'vuex';
import SideBarMenu from '../../components/SideBarMenu.vue';
import IdleTimer from '../../components/IdleTimer.vue';

import backgroundUrl from '@/assets/img/bg/bg-ep-LUX.jpg';

export default {
  components: {
    SideBarMenu,
    IdleTimer,
  },
  data() {
    return {
      show: false,
      backgroundUrl,
      projectRef: this.$store.getters.currentProject.ref,
      siteFullTemp: '',
    };
  },
  methods: {
    ...mapMutations(['SAVE_CONTEXT']),
  },
  mounted() {
    this.show = !this.show;
    const { path } = this.$route;
    this.SAVE_CONTEXT(path);
  },
  computed: {},
};
</script>
<style scoped>
iframe {
  width: 100%;
  height: 100%;
}
.company-details {
  margin-left: 52px;
}
</style>