const locations = [
  {
    value: 'locationInt',
    label: 'Intérieur',
  },
  {
    value: 'locationExt',
    label: 'Extétieur',
  },
  {
    value: 'locationCeiling',
    label: 'Plénum',
  },
  {
    value: 'locationFloor',
    label: 'Faux-plancher',
  },
  {
    value: 'locationFacade',
    label: 'Façade',
  },
  {
    value: 'locationRoof',
    label: 'Toiture',
  },
  {
    value: 'locationServiceShaft',
    label: 'Gaine technique',
  },
];
export default locations;
