<template>
  <b-modal
    v-model="isVisible"
    title="Map"
    @shown="renderPdf"
    @hide="resetMarkerVisibility"
    size="lg"
  >
    <div
      v-loading="loading"
      class="map-container"
    >
      <div
        v-if="drawingUrl"
        class="canvas-container"
        :id="'canvas-container-' + canvasId"
      >
        <canvas :id="'pdf-renderer-' + canvasId"></canvas>
        <div
          v-if="markerVisible"
          :style="{ top: markerY + '%', left: markerX + '%', transform: 'translate(-50%, -100%)' }"
          class="marker-icon"
        >
          <font-awesome-icon :icon="['fass', 'location-dot']" />
          <!-- <font-awesome-icon
            class="icon"
            icon="fa-solid fa-location-dot"
          /> -->
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import pdfjsLib from 'pdfjs-dist/build/pdf';
import 'pdfjs-dist/web/pdf_viewer.css';
pdfjsLib.GlobalWorkerOptions.workerSrc = 'pdfjs-dist/build/pdf.worker.js';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { Loading } from 'element-ui';

library.add(faLocationDot);

export default {
  name: 'ModalMap',
  components: {
    FontAwesomeIcon,
  },
  props: {
    drawingUrl: {
      type: String,
      required: true,
    },
    markerX: {
      type: Number,
      required: true,
    },
    markerY: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isVisible: false,
      pdf: null,
      canvasId: Math.random().toString(36).substr(2, 9), // unique ID for canvas
      scale: 1,
      canvas: {
        width: null,
        height: null,
      },
      markerVisible: false,
      loading: true, // Initial loading state
    };
  },
  methods: {
    show() {
      this.isVisible = true;
    },
    hide() {
      this.isVisible = false;
      this.resetMarkerVisibility();
    },
    async renderPdf() {
      this.loading = true; // Start loading
      const loadingTask = pdfjsLib.getDocument(this.drawingUrl);
      loadingTask.promise.then((pdf) => {
        this.pdf = pdf;
        this.renderPage();
      });
    },
    renderPage() {
      this.pdf.getPage(1).then((page) => {
        let canvas = document.getElementById('pdf-renderer-' + this.canvasId);
        let ctx = canvas.getContext('2d');
        const container = document.getElementById(
          'canvas-container-' + this.canvasId
        );

        // Scale the PDF to fit within the container
        const viewport = page.getViewport({ scale: this.scale });

        canvas.width = viewport.width;
        canvas.height = viewport.height;
        this.canvas.width = viewport.width;
        this.canvas.height = viewport.height;

        const renderContext = {
          canvasContext: ctx,
          viewport: viewport,
        };

        page.render(renderContext).promise.then(() => {
          this.loading = false; // End loading
          this.markerVisible = true; // Set marker visible after PDF rendering
          this.positionMarker();
        });
      });
    },
    positionMarker() {
      const container = document.getElementById(
        'canvas-container-' + this.canvasId
      );
      const markerIcon = container.querySelector('.marker-icon');

      if (markerIcon) {
        const scrollToX =
          (this.markerX / 100) * this.canvas.width - container.clientWidth / 2;
        const scrollToY =
          (this.markerY / 100) * this.canvas.height -
          container.clientHeight / 2;

        container.scrollTo(scrollToX, scrollToY);
      }
    },
    resetMarkerVisibility() {
      this.markerVisible = false;
    },
  },
};
</script>

<style scoped>
.map-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.canvas-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
}

canvas {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.marker-icon {
  position: absolute;
  color: red; /* Make sure the icon is visible */
  font-size: 24px; /* Adjust size as needed */
  transform: translate(-50%, -100%); /* Adjusted to position bottom center at the coordinate */
}
</style>
