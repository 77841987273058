<template>
  <b-row>
    <b-col
      cols="12"
      md="12"
      lg="12"
      class="mt-4 mb-4 d-flex justify-content-center"
    >

      <StreamBarcodeReader
        ref="scanner"
        @decode="onDecode"
        @loaded="onLoaded"
        @init="onInit"
        @error="onError"
      ></StreamBarcodeReader>

    </b-col>
  </b-row>
</template>

<script>
// NOTA: Using previous scannner version (more ompatible with Vue 2)
// reason: latest does not allow track.stop() on beforeUnmount
// version: vue-barcode-reader@0.0.3
import { mapMutations } from 'vuex';
import { StreamBarcodeReader } from 'vue-barcode-reader';
import axiosResource from '@/utils/axiosResource';

export default {
  name: 'scanner',
  components: {
    StreamBarcodeReader,
  },
  data() {
    return {
      show: false,
      scan: '',
      buildingRef: '',
      buildingUuid: '',
      stream: null,
      errorMessage: '',
    };
  },
  methods: {
    ...mapMutations(['SAVE_CONTEXT']),
    onDecode(code) {
      this.code = code;
      this.decode();
    },
    onInit(promise) {
      promise
        .then(() => {
          console.log('Camera permission granted.');
        })
        .catch((error) => {
          console.error('Camera permission denied:', error);
          // Provide user feedback here
        });
    },
    onError(error) {
      console.error('Error:', error);
      // Provide user feedback here
    },
    onLoaded() {
      console.log(`Ready to start scanning barcodes`);
    },
    async decode() {
      const clgStyle3 = 'color: magenta;';
      console.log('%c' + 'decode', clgStyle3);
      const projectRef = this.$store.getters.currentProject.ref;
      const clgStyle2 = 'color: white;background-color: red;';
      console.log('%c' + projectRef, clgStyle2);
      const clgStyle = 'color: magenta; font-size: 16pt;';
      console.log('%c' + `${this.code}`, clgStyle);
      const projectId = this.$store.getters.currentProject.id;
      let level, zone, serial, definition;
      const str = this.code.split(' ');
      const buildingRef = str[0].slice(4, 8);
      this.buildingRef = buildingRef;
      // Decode is function of site (different encoding site to site)
      switch (projectId) {
        case 1: // BRU
        case 3: // LUX
          if (str.length === 3) {
            level = str[1].slice(0, 2);
            zone = str[1].slice(2, 3);
            serial = str[1].slice(3);
            definition = str[2];
          } else if (str.length === 2) {
            // Sometines there is no space before local definition
            level = str[1].slice(0, 2);
            zone = str[1].slice(2, 3);
            serial = str[1].slice(3, 7);
            definition = str[1].slice(-2);
          } else {
            // Error SWAL
          }
          break;
        case 2: // STR
          if (str.length === 3) {
            zone = str[1].slice(0, 1);
            level = str[1].slice(1, 3);
            serial = str[1].slice(3);
            definition = str[2];
          } else if (str.length === 2) {
            // Sometines there is no LOCAL DEFINITION at all for BU in LOW building
            zone = str[1].slice(0, 1);
            level = str[1].slice(1, 3);
            serial = str[1].slice(3, 7);
            definition = 'BU';
          } else {
            // Error SWAL
          }
          break;
        default:
          if (str.length === 3) {
            level = str[1].slice(0, 2);
            zone = str[1].slice(2, 3);
            serial = str[1].slice(3);
            definition = str[2];
          } else if (str.length === 2) {
            // Sometines there is no space before local definition
            level = str[1].slice(0, 2);
            zone = str[1].slice(2, 3);
            serial = str[1].slice(3, 7);
            definition = str[1].slice(-2);
          } else {
            // Error SWAL
          }
          break;
      }
      console.log(buildingRef, level, zone, serial, definition);
      // Find building id from filters data
      // SECURITY: if no building id, keep scanning and do not reroute to explore
      this.$store.commit('setBuilding', {
        label: this.buildingRef,
      });
      await this.fetchBuildingUuid();
      // Set store
      this.$store.commit('setLevel', level);
      this.$store.commit('setZone', zone);
      this.$store.commit('setSerial', serial);
      this.$store.commit('setDefinition', {
        value: definition,
        label: definition,
      });
      this.$router.push('/explore/');
    },
    async fetchBuildingUuid() {
      const clgStyle = 'color: magenta;';
      console.log('%c' + 'fetchBuildingUuid', clgStyle);
      this.projectUuid = this.$store.getters.currentProject.uuid;
      axiosResource
        .get('/admin/buildings/building/accessLess/' + this.projectUuid)
        .then((res) => {
          res.data.find((value) => {
            console.log(`fetchBuildingUuid`, value);
            if (value.ref == this.buildingRef) {
              this.buildingUuid = value.uuid;
              this.buildingRef = value.ref;
              // Set store (with remaining buildingId)
              console.log(
                `BUILDING from store BEFORE=`,
                this.$store.getters.building
              );
              this.$store.commit('setBuilding', {
                uuid: this.buildingUuid,
                label: this.buildingRef,
              });
              console.log(
                `BUILDING from store AFTER=`,
                this.$store.getters.building
              );
            }
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$globalSwal.error(err.response.data.msg);
        });
    },
  },
  mounted() {
    this.show = !this.show;
    const { path } = this.$route;
    this.SAVE_CONTEXT(path);
  },
  beforeUnmount() {
    this.$refs.scanner.codeReader.stream.getTracks().forEach(function (track) {
      track.stop();
    });
  },
};
</script>
<style scoped>
.wrapper-center {
    padding-right: 0 !important;
    margin-right: 0 !important;
}
@media (min-width: 768px) { 
    .wrapper-center {
        padding-right: 0 !important;
        margin-right: 0 !important;
    }
}
@media (min-width: 992px) { 
    .wrapper-center {
        padding-right: 0 !important;
        margin-right: 0 !important;
    }
}
@media (min-width: 1200px) { 
    .wrapper-center {
        padding-right: 0 !important;
        margin-right: 0 !important;
    }
}
</style>