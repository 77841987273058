<template>
  <div class="container-global-stats">
    <b-row class="mx-auto pt-4">
      <b-col
        cols="12"
        md="12"
        lg="4"
        class="mb-3"
      >
        <General />
      </b-col>
      <b-col
        cols="12"
        md="12"
        lg="4"
        class="mb-3 bg-white"
      >
        <b-card class="h-100 card-goso-stats">
          <Status ref="status" />
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="12"
        lg="4"
        class="mb-3 bg-white"
      >
        <b-card class="h-100 card-goso-stats">
          <StatusAP />
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mx-auto pb-4">
      <b-col
        cols="12"
        md="12"
        lg="4"
        class="mb-3 bg-white"
      >
        <b-card class="h-100 card-goso-stats">
          <Quick />
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="12"
        lg="4"
        class="text-center mb-3 bg-white"
      >
        <b-card class="h-100 card-goso-stats">
          <Detailed />
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="12"
        lg="4"
        class="text-center mb-3 bg-white"
      >
        <b-card class="h-100 card-goso-stats">
          <LocationHint />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
require('highcharts/modules/exporting')(Highcharts);

import Quick from '../../components/Stats/Global/Quick.vue';
import Detailed from '../../components/Stats/Global/Detailed.vue';
import LocationHint from './Global/LocationHint.vue';
import Status from '../../components/Stats/Global/Status.vue';
import StatusAP from '../../components/Stats/Global/StatusAP.vue';
import General from '../../components/Stats/Global/General.vue';

export default {
  name: 'global-view',
  components: {
    Quick,
    Detailed,
    LocationHint,
    Status,
    StatusAP,
    General,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.container-global-stats {
  width: 100%;
  padding: 0 10px; /* Add padding to container to maintain margin on mobile */
}
.card-body {
  padding: 0 !important;
}
.mb-3 {
  margin-bottom: 1rem;
}
.h-100 {
  height: 100%;
}
.card-goso-stats {
  border: none; 
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 15px;
  overflow: hidden;
}
</style>
