<template>
  <div>
    <div class="block-stats">
      <b-row>
        <b-col cols="8">
          <span class="figure">{{ ALL }}</span>
          <span class="designation">TOTAL</span>
        </b-col>
        <b-col cols="4">
          <font-awesome-icon
            class="icon float-right fa-3x"
            icon="fa-duotone fa-file"
          />
        </b-col>
      </b-row>
    </div>
    <div class="block-stats">
      <b-row>
        <b-col cols="8">
          <span class="figure">{{ DETAILED }}</span>
          <span class="designation">{{ $t('highcharts.detailed') }}</span>
        </b-col>
        <b-col cols="4">
          <font-awesome-icon
            class="icon float-right fa-3x"
            icon="fa-duotone fa-images"
          />
        </b-col>
      </b-row>
    </div>
    <div class="block-stats">
      <b-row>
        <b-col cols="8">
          <span class="figure">{{ QUICK }}</span>
          <span class="designation">QUICK</span>
        </b-col>
        <b-col cols="4">
          <font-awesome-icon
            class="icon float-right fa-3x"
            icon="fa-duotone fa-circle-bolt"
          />
        </b-col>
      </b-row>
    </div>
    <!-- <div class="block-stats">
      <b-row>
        <b-col cols="8">
          <span class="figure">{{ CHECKLIST }}</span>
          <span class="designation">CHECK lists</span>
        </b-col>
        <b-col cols="4">
          <font-awesome-icon
            class="icon float-right fa-3x"
            icon="fa-duotone fa-list"
          />
        </b-col>
      </b-row>
    </div> -->
  </div>
</template>

<script>
import { formatNumberWithThousandSeparator } from '@/utils/functions';

export default {
  name: 'general',
  data() {
    return {};
  },
  computed: {
    ALL() {
      return this.formatNumber(this.$store.getters.chart.general.ALL);
    },
    DETAILED() {
      return this.formatNumber(this.$store.getters.chart.general.DETAILED);
    },
    QUICK() {
      return this.formatNumber(this.$store.getters.chart.general.QUICK);
    },
    CHECKLIST() {
      return this.formatNumber(this.$store.getters.chart.general.CHECKLIST);
    },
  },
  methods: {
    formatNumber(value) {
      if (Array.isArray(value)) {
        return value.map(formatNumberWithThousandSeparator);
      }
      return formatNumberWithThousandSeparator(value);
    },
  },
  mounted() {
    this.$store.dispatch('fetchStats', 'general');
  },
};
</script>
<style scoped>

.block-stats {
  height: 89px;
  background-color: #ecf5ff;
  border-radius: 8px;
  color: #409eff;
  padding-top: 8px;
  margin-bottom: 14px;
}
.figure {
  font-size: 38px;
  line-height: 38px;
  font-weight: 600;
  margin-top: 16px;
  margin-left: 20px;
  /* width: 100%; */
}
.designation {
  font-size: 16px;
  font-weight: 400;
  margin-top: 2px;
  margin-left: 16px;
}
.icon {
  position: relative;
  top: 12px;
  right: 20px;
}
</style>
