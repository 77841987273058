<template>
  <b-container
    fluid
    v-loading="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0)"
    class="history-container"
  >
    <div class="history-scroll">
      <div
        v-for="item in history"
        :key="item.id"
        class="mb-1"
      >
        <component
          :is="getComponent(item.actionType)"
          :action-type="item.actionType"
          :comment="item.comment"
          :status="item.actionType"
          :wi-data="item.wiRef"
          :hashtag="item.hashtag"
          :user="item.user"
          :date="item.date"
          :toUser="item.toUser"
          :toEntity="item.toEntity"
          :byEmail="item.byEmail"
          :target="'table'"
        />
      </div>
    </div>
  </b-container>
</template>

<script>
import axiosResource from '@/utils/axiosResource';
import Status from './HistoryRow_status.vue';
import HistoryRow_comment from './HistoryRow_comment.vue';
import HistoryRow_hashtag from './HistoryRow_hashtag.vue';
import HistoryRow_wi from './HistoryRow_wi.vue';
import HistoryRow_edited from './HistoryRow_edited.vue';
import HistoryRow_addressed from './HistoryRow_addressed.vue';

export default {
  name: 'history',
  props: ['reportUuid'],
  components: {
    Status,
    HistoryRow_comment,
    HistoryRow_hashtag,
    HistoryRow_wi,
    HistoryRow_edited,
    HistoryRow_addressed,
  },
  data() {
    return {
      history: null,
      loading: true,
    };
  },
  mounted() {
    this.fetchHistory();
  },
  computed: {},
  methods: {
    getComponent(actionType) {
      if (actionType.startsWith('COMMENT')) {
        return 'HistoryRow_comment';
      } else if (actionType.startsWith('HASHTAG')) {
        return 'HistoryRow_hashtag';
      } else if (actionType.startsWith('WI')) {
        return 'HistoryRow_wi';
      } else if (actionType.startsWith('EDITED')) {
        return 'HistoryRow_edited';
      } else if (actionType.startsWith('ADDRESSED')) {
        return 'HistoryRow_addressed';
      } else {
        return 'Status'; // Default to Status for all other types
      }
    },
    fetchHistory() {
      const projectUuid = this.$store.getters.currentProject.uuid;
      const reportUuid = this.reportUuid;
      console.log(`fetchHistory for reportUuid=${reportUuid}`);
      axiosResource
        .get(`/reports/history/${projectUuid}/${reportUuid}`)
        .then((res) => {
          this.history = res.data.results;
          console.log(this.history);
          this.loading = false;
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
  },
};
</script>

<style>
.history-container {
  min-height: 20px;
}
.history-scroll {
  max-height: 400px; /* Adjust this value as needed */
  overflow-y: auto; /* Enable vertical scrolling */
  padding-right: 15px; /* Optional: Add padding to account for scrollbar */
}

.history-user {
  color: gray;
  font-size: 9px;
  padding-left: 2px;
  padding-right: 2px;
}
.history-date {
  color: gray;
  font-size: 9px;
  padding-left: 2px;
  padding-right: 2px;
}

.history-status {
  font-size: 10px;
  margin-left: 6px;
}

.history-text-success {
  font-weight: 200;
  color: #28a745; /* Green color for added comments */
}

.history-text-primary {
  font-weight: 200;
  color: #007bff; /* Blue color for removed comments */
}

.history-detail {
  font-size: 11px;
  font-weight: 200;
  color: #000000;
}

.history-generic {
  display: inline-block; /* Make the div fit the text content */
  border-radius: 4px; /* Optional: Add border radius for better appearance */
  text-align: right; /* Align text to the right */
}
</style>

<style scoped>
/deep/ .el-loading-mask {
  background-color: transparent !important;
}
</style>
