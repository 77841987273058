<template>
  <b-row>
    <b-col
      cols="12"
      lg="12"
      xl="8"
    >
      <div
        @dragenter.prevent="toggleActive"
        @dragleave.prevent="toggleActive"
        @dragover.prevent
        @drop.prevent="toggleActive"
        @drop="droppedFile"
        for="dropzoneFile"
        :class="{ 'active-dropzone': active }"
        class="dropzone"
      >
        <label for="dropzoneFile">
          <i class="el-icon-upload"></i>
          <span>{{ $t('dropzone.drop') }} <span class="dropzone-blue">{{ $t('dropzone.click') }}</span></span>
        </label>
        <input
          type="file"
          id="dropzoneFile"
          @change="selectedFile"
        />
      </div>
      <ImageBlock
        v-for="file in fileList"
        :key="file.key"
        :file="file"
        @deleteImage="deleteImage"
        @openEditDialog="openEditDialog"
      />
      <ProgressBlock
        v-if="progressBlock"
        @stopUpload="stopUpload"
        :progress="uploadProgress.value"
      />
      <el-dialog
        fullscreen
        :visible.sync="dialogVisible"
        @opened="setEditor"
        @close="unsetEditor"
      >
        <span slot="title">
          <el-dropdown :hide-on-click="true">
            <span class="el-dropdown-link">
              <font-awesome-icon
                :style="{color: currentColor }"
                icon="fa-solid fa-square"
                size="2x"
                @click="debug"
              />
            </span>
            <el-dropdown-menu
              slot="dropdown"
              trigger="click"
              ref="messageDrop"
            >
              <font-awesome-icon
                icon="fa-solid fa-square"
                size="2x"
                class="mx-1 my-1 paint-red"
                @click="changeColor('#e40000')"
              />
              <font-awesome-icon
                icon="fa-solid fa-square"
                size="2x"
                class="mx-1 my-1 paint-yellow"
                @click="changeColor('#e8eb34')"
              />
              <font-awesome-icon
                icon="fa-solid fa-square"
                size="2x"
                class="mx-1 my-1 paint-purple"
                @click="changeColor('#a834eb')"
              />
              <font-awesome-icon
                icon="fa-solid fa-square"
                size="2x"
                class="mx-1 my-1 paint-green"
                @click="changeColor('#65c31a')"
              />
              <br>
              <font-awesome-icon
                icon="fa-solid fa-square"
                size="2x"
                class="mx-1 my-1 paint-blue"
                @click="changeColor('#34b7eb')"
              />
              <font-awesome-icon
                icon="fa-solid fa-square"
                size="2x"
                class="mx-1 my-1 paint-magenta"
                @click="changeColor('#eb34df')"
              />
              <font-awesome-icon
                icon="fa-solid fa-square"
                size="2x"
                class="mx-1 my-1 paint-navy"
                @click="changeColor('#1a10ad')"
              />
              <font-awesome-icon
                icon="fa-solid fa-square"
                size="2x"
                class="mx-1 my-1 paint-black"
                @click="changeColor('#000')"
              />
            </el-dropdown-menu>
          </el-dropdown>
          <font-awesome-icon
            icon="fa-regular fa-rotate-left"
            class="tool ml-md-4 ml-2"
            @click="toolSetter('undo')"
          />
          <font-awesome-icon
            icon="fa-regular fa-rotate-right"
            class="tool ml-md-4 ml-2"
            @click="toolSetter('redo')"
          />
          <font-awesome-icon
            icon="fa-regular fa-trash-can"
            class="tool ml-md-4 ml-2"
            @click="toolSetter('clear')"
          />
          <font-awesome-icon
            icon="fa-regular fa-eraser"
            class="tool ml-md-4 ml-2"
            @click="toolSetter('eraser')"
          />
          <font-awesome-icon
            icon="fa-regular fa-text"
            class="tool ml-md-4 ml-2"
            @click="toolSetter('text')"
          />
          <font-awesome-icon
            icon="fa-regular fa-circle"
            class="tool ml-md-4 ml-2"
            @click="toolSetter('circle')"
          />
          <font-awesome-icon
            icon="fa-regular fa-square"
            class="tool ml-md-4 ml-2"
            @click="toolSetter('rect')"
          />
          <font-awesome-icon
            icon="fa-regular fa-arrow-down"
            class="tool ml-md-4 ml-2"
            @click="toolSetter('arrow')"
          />
          <font-awesome-icon
            icon="fa-regular fa-arrows-up-down-left-right"
            class="tool ml-md-4 ml-2"
            @click="toolSetter('select')"
          />
          <font-awesome-icon
            icon="fa-solid fa-file-arrow-down"
            class="tool ml-md-4 ml-2"
            @click="toolSetter('download')"
          />
        </span>
        <Editor
          :canvasWidth="canvasWidth"
          :canvasHeight="canvasHeight"
          ref="editor"
          editorId="canvasId"
          v-loading="loading"
          v-if="dialogVisible"
          class="
      d-flex
      justify-content-center"
        />
        <span
          slot="footer"
          class="dialog-footer fixed-bottom mb-4 mx-4"
        >
          <el-button @click.prevent="dialogVisible = false">{{ $t('action.cancel') }}</el-button>
          <el-button
            type="primary"
            @click.prevent="replaceImage"
          >{{ $t('action.save') }}</el-button>
        </span>
      </el-dialog>
    </b-col>
  </b-row>
</template>

<script>
import axiosResource from '@/utils/axiosResource';
import ImageBlock from './ImageBlock.vue';
import ProgressBlock from './ProgressBlock.vue';
import swal from 'sweetalert2';
import Editor from 'vue-image-markup';

const swalBootsrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export default {
  name: 'dropzone',
  components: {
    ImageBlock,
    ProgressBlock,
    Editor,
  },
  props: {
    mode: {
      type: String,
      required: true,
    },
    report: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      active: false,
      progressBlock: false,
      form: {
        file: null,
      },
      fileList: [],
      uploadProgress: {
        value: 0,
        max: 100,
        show: false,
      },
      imageUrl: '',
      imageDimensions: [],
      dialogVisible: false,
      currentColor: 'red',
      loading: true,
      canvasWidth: 0,
      canvasHeight: 0,
    };
  },
  mounted() {
    if (this.mode === 'edit') {
      this.initializeFromReport();
    }
  },
  methods: {
    initializeFromReport() {
      const images = [
        { key: this.report.photo1Key, url: this.report.presignedUrlPhoto1 },
        { key: this.report.photo2Key, url: this.report.presignedUrlPhoto2 },
        { key: this.report.photo3Key, url: this.report.presignedUrlPhoto3 },
      ];

      this.fileList = images
        .filter((image) => image.url) // Only include images that have URLs
        .map((image) => ({
          key: image.key,
          presignedUrl: image.url,
        }));

      console.log('Initialized fileList:', this.fileList);
      // Emit file has changed
      this.$emit('files-changed', this.fileList);
    },
    saveObjects() {
      const text = this.$refs.editor.getObjectsById('title');
      console.log(text);
      const circle = this.$refs.editor.getObjectsById('myCircle');
      console.log(circle);
    },
    openEditDialog(file) {
      console.log(`openEditDialog file: ${file.presignedUrl}`);
      console.log(file);

      this.imageKey = file.key;
      this.dialogVisible = true;
      this.imageUrl = file.presignedUrl;
    },
    debug() {
      console.log('debug');
      this.$refs.editor.set('selectMode');
    },
    changeColor(color) {
      this.currentColor = color;
      // Set mode to select to prevent color not being set up (force user to select tool)
      this.$refs.editor.set('selectMode');
    },
    toolSetter(tool) {
      let circleModeParams = {
        id: 'myCircle',
        stroke: this.currentColor,
        strokeWidth: 3,
      };
      let rectModeParams = { stroke: this.currentColor, strokeWidth: 3 };
      const textModeParams = {
        id: 'title',
        fill: this.currentColor,
        fontFamily: 'Verdana',
        fontSize: 28,
      };
      let arrowModeParams = { stroke: this.currentColor, strokeWidth: '3' };

      switch (tool) {
        case 'undo':
          this.$refs.editor.undo();
          break;
        case 'redo':
          this.$refs.editor.redo();
          break;
        case 'clear':
          this.$refs.editor.clear();
          this.$refs.editor.setBackgroundImage(this.imageUrl);
          break;
        case 'eraser':
          this.$refs.editor.set('eraser');
          break;
        case 'text':
          this.$refs.editor.set('text', textModeParams);
          break;
        case 'circle':
          this.$refs.editor.set('circle', circleModeParams);
          break;
        case 'rect':
          this.$refs.editor.set('rect', rectModeParams);
          break;
        case 'arrow':
          this.$refs.editor.set('arrow', arrowModeParams);
          break;
        case 'select':
          this.$refs.editor.set('selectMode');
          break;
        case 'download':
          this.downloadImage();
          break;
        default:
          break;
      }
    },
    async resizeImage(base64Str) {
      let originalWidth = this.imageDimensions.width;
      let originalHeight = this.imageDimensions.height;
      let resizeBase64 = await new Promise((resolve) => {
        let img = new Image();
        img.src = base64Str;
        img.onload = function () {
          let canvas = document.createElement('canvas');
          canvas.width = originalWidth;
          canvas.height = originalHeight;
          let ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          resolve(canvas.toDataURL());
        };
      });
      return resizeBase64;
    },
    async downloadImage() {
      var img = new Image();
      img.src = await this.resizeImage(this.$refs.editor.saveImage());
      console.log('IMAGE SRC before download');
      console.log(img.src);

      var a = document.createElement('a');
      a.href = img.src;
      a.download = 'Image.png';
      a.click();
    },
    setEditor() {
      // Get max available dimensions
      const dialogHeader = document.querySelector('.el-dialog__header');
      const dialogFooter = document.querySelector('.el-dialog__footer');
      const dialogBody = document.querySelector('.el-dialog__body');
      const dialogFull = document.querySelector('.el-dialog');
      const availableWidth = dialogBody.clientWidth - 40;
      const availableHeight =
        dialogFull.clientHeight -
        dialogHeader.clientHeight -
        dialogFooter.clientHeight -
        100;
      console.log(`availableHeight= ${availableHeight}`);

      // Get image dimensions
      const img = new Image();
      let imgWidth;
      let imgHeight;
      const vm = this;

      const promise = new Promise((resolve) => {
        img.onload = function () {
          imgWidth = this.width;
          imgHeight = this.height;
          vm.imageDimensions = { width: imgWidth, height: imgHeight };
          console.log('this.imageDimensions is', this.imageDimensions);

          const calculateAspectRatioFit = (
            srcWidth,
            srcHeight,
            maxWidth,
            maxHeight
          ) => {
            let ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
            console.log('RATIO=', ratio);
            return { canvasW: srcWidth * ratio, canvasH: srcHeight * ratio };
          };
          let { canvasW, canvasH } = calculateAspectRatioFit(
            imgWidth,
            imgHeight,
            availableWidth,
            availableHeight
          );
          vm.canvasWidth = canvasW;
          vm.canvasHeight = canvasH;
          resolve();
        };
        console.log('vm.imageUrl ALIAS Bucket link = ' + vm.imageUrl);
        img.src = vm.imageUrl;
      });

      promise.then(() => {
        // Set canvas
        vm.$refs.editor.canvas.setDimensions({
          width: vm.canvasWidth,
          height: vm.canvasHeight,
        });
        // Set image
        vm.$refs.editor.setBackgroundImage(vm.imageUrl);
        // Stop loading
        vm.loading = false;
        this.imageDimensions = vm.imageDimensions;
      });

      // Define canvas size upon image dimensions
    },
    calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
      let ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
      return { width: srcWidth * ratio, height: srcHeight * ratio };
    },
    unsetEditor() {
      this.loading = true;
    },
    deleteImage(file) {
      console.log(file);

      swalBootsrap
        .fire({
          icon: 'question',
          title: 'Do you want to delete this file?',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
        })
        .then((result) => {
          if (result.isConfirmed) {
            const key = file.key;
            axiosResource
              .delete(`/uploads/deleteImage/${key}`)
              .then((response) => {
                console.log(response);

                for (let i = 0; i < this.fileList.length; i++) {
                  if (this.fileList[i].key == file.key) {
                    this.fileList.splice(i, 1);
                    // Emit file has changed
                    this.$emit('files-changed', this.fileList);

                    swalBootsrap.fire({
                      icon: 'info',
                      title: 'File deleted',
                      showConfirmButton: false,
                      timer: 1000,
                    });
                  }
                }

                this.progressBlock = false;
                this.uploadProgress.value = 0;
              })
              .catch((err) => {
                this.$globalSwal.error(err.response.data.msg);
              });
          }
        });
    },
    stopUpload() {
      this.progressBlock = false;
      this.uploadProgress.value = 0;
    },
    selectedFile(event) {
      this.form.file = event.target.files[0];
      this.uploadFile();
    },
    droppedFile(event) {
      this.form.file = event.dataTransfer.files[0];
      this.uploadFile();
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    async replaceImage() {
      const imgBase64 = await this.resizeImage(this.$refs.editor.saveImage());
      var file = this.dataURLtoFile(imgBase64, 'test.txt');
      let formData = new FormData();
      formData.append('image', file);
      axiosResource
        .post(`/uploads/replaceImage/${this.imageKey}`, formData, {
          onUploadProgress: (uploadEvent) => {
            this.loading = true;
            this.uploadProgress.value = Math.round(
              (uploadEvent.loaded / uploadEvent.total) * 100
            );
          },
        })
        .then((response) => {
          console.log('response from replaceImage() => ');

          console.log(response);
          console.log(this.fileList);

          this.dialogVisible = false;

          this.progressBlock = false;
          this.uploadProgress.value = 0;
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    uploadFile() {
      this.uploadProgress.show = true;
      let formData = new FormData();
      formData.append('image', this.form.file);
      console.log('this.form.file ----------');

      console.log(this.form.file);

      this.progressBlock = true;

      axiosResource
        .post(`/uploads/image`, formData, {
          onUploadProgress: (uploadEvent) => {
            this.uploadProgress.value = Math.round(
              (uploadEvent.loaded / uploadEvent.total) * 100
            );
          },
        })
        .then((response) => {
          console.log(response);

          this.fileList.push(response.data);
          console.log(this.fileList);

          this.progressBlock = false;
          this.uploadProgress.value = 0;
          // Emit file has changed
          this.$emit('files-changed', this.fileList);
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    toggleActive() {
      this.active = !this.active;
    },
  },
};
</script>

<style scoped lang="scss">
.dropzone {
  width: 100%;
  height: 180px;
  row-gap: 8px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  background-color: #fff;
  transition: 0.3s ease all;

  label {
    display: block;
    width: 100%;
    height: 180px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  i {
    font-size: 67px;
    color: #c0c4cc;
    margin: 0px 0 16px;
    line-height: 50px;
  }
}
.dropzone:hover {
  border-color: #409eff;
}
.active-dropzone {
  background-color: rgba(32, 159, 255, 0.06);
  border: 2px dashed #409eff;

  i {
    margin: 0px 0 16px;
  }
}

input {
  display: none;
}
</style>

<style>
/* EDIT IMAGE DIALOG CSS */

.el-dialog__headerbtn .el-dialog__close {
  color: #dc3545 !important;
}

.tool:hover {
  color: #409eff;
}
.tool {
  font-size: 1rem;
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .tool {
    font-size: 1.2rem;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .tool {
    font-size: 1.5rem;
  }
}

/* Large devices (desktops, 992px and up) */
/* @media (min-width: 992px) { } */

/* Extra large devices (large desktops, 1200px and up) */
/* @media (min-width: 1200px) {  } */
</style>
