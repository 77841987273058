<template>
  <div>

    <p class="text-left localization-title">{{ $t('highcharts.localization') }}</p>
    <p class="text-left localization-subtitle">{{ this.$t('highcharts.overPeriod'), }}</p>

    <div v-if="INT != 0">
      <div class="d-flex mt-2 mx-4">
        {{ $t('location.locationInt') }}
        <span class="d-inline-block ml-auto">{{ INT }}</span>
      </div>
      <el-progress
        :percentage="INT * 100 / SUM"
        :format="format"
      ></el-progress>
    </div>

    <div v-if="EXT != 0">
      <div class="d-flex mt-2 mx-4">
        {{ $t('location.locationExt') }}
        <span class="d-inline-block ml-auto">{{ EXT }}</span>
      </div>
      <el-progress
        :percentage="EXT * 100 / SUM"
        :format="format"
      ></el-progress>
    </div>

    <div v-if="CEILING != 0">
      <div class="d-flex mt-2 mx-4">
        {{ $t('location.locationCeiling') }}
        <span class="d-inline-block ml-auto">{{ CEILING }}</span>
      </div>
      <el-progress
        :percentage="CEILING * 100 / SUM"
        :format="format"
      ></el-progress>
    </div>

    <div v-if="FLOOR != 0">
      <div class="d-flex mt-2 mx-4">
        {{ $t('location.locationFloor') }}
        <span class="d-inline-block ml-auto">{{ FLOOR }}</span>
      </div>
      <el-progress
        :percentage="FLOOR * 100 / SUM"
        :format="format"
      ></el-progress>
    </div>

    <div v-if="FACADE != 0">
      <div class="d-flex mt-2 mx-4">
        {{ $t('location.locationFacade') }}
        <span class="d-inline-block ml-auto">{{ FACADE }}</span>
      </div>
      <el-progress
        :percentage="FACADE * 100 / SUM"
        :format="format"
      ></el-progress>
    </div>

    <div v-if="ROOF != 0">
      <div class="d-flex mt-2 mx-4">
        {{ $t('location.locationRoof') }}
        <span class="d-inline-block ml-auto">{{ ROOF }}</span>
      </div>
      <el-progress
        :percentage="ROOF * 100 / SUM"
        :format="format"
      ></el-progress>
    </div>

    <div v-if="SHAFT != 0">
      <div class="d-flex mt-2 mx-4">
        {{ $t('location.locationServiceShaft') }}
        <span class="d-inline-block ml-auto">{{ SHAFT }}</span>
      </div>
      <el-progress
        :percentage="SHAFT * 100 / SUM"
        :format="format"
      ></el-progress>
    </div>

  </div>
</template>

<script>
export default {
  name: 'location-hint',
  data() {
    return {};
  },
  computed: {
    SUM() {
      const SUM =
        this.INT +
        this.EXT +
        this.CEILING +
        this.FLOOR +
        this.FACADE +
        this.ROOF +
        this.SHAFT;
      return SUM;
    },
    INT() {
      return this.$store.getters.chart.locationHint.INT;
    },
    EXT() {
      return this.$store.getters.chart.locationHint.EXT;
    },
    CEILING() {
      return this.$store.getters.chart.locationHint.CEILING;
    },
    FLOOR() {
      return this.$store.getters.chart.locationHint.FLOOR;
    },
    FACADE() {
      return this.$store.getters.chart.locationHint.FACADE;
    },
    ROOF() {
      return this.$store.getters.chart.locationHint.ROOF;
    },
    SHAFT() {
      return this.$store.getters.chart.locationHint.SHAFT;
    },
  },
  methods: {
    format(percentage) {
      return percentage === 100 ? '.' : ``;
    },
  },
  mounted() {
    this.$store.dispatch('fetchStats', 'locationHint');
  },
};
</script>
<style scoped>
.localization-title {
  font-size: 16px; 
  margin-top: 6px; 
  margin-left: 12px; 
  margin-bottom: 2px; 
  font-weight: 500;
}
.localization-subtitle {
  font-size: 10px; 
  margin-left: 12px; 
  font-weight: 400; 
  color: rgb(102, 102, 102); 
  fill: rgb(102, 102, 102);
}
</style>
