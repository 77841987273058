const definitions = [
  {
    value: 'BU',
    label: 'BU',
  },
  {
    value: 'CI',
    label: 'CI',
  },
  {
    value: 'ES',
    label: 'ES',
  },
  {
    value: 'EX',
    label: 'EX',
  },
  {
    value: 'LT',
    label: 'LT',
  },
  {
    value: 'PA',
    label: 'PA',
  },
  {
    value: 'RE',
    label: 'RE',
  },
  {
    value: 'SA',
    label: 'SA',
  },
  {
    value: 'SR',
    label: 'SR',
  },
  {
    value: 'ST',
    label: 'ST',
  },
  {
    value: 'TO',
    label: 'TO',
  },
  {
    value: 'VR',
    label: 'VR',
  },
  {
    value: 'FE',
    label: 'FE',
  },
  {
    value: 'PO',
    label: 'PO',
  },
  {
    value: 'PCF',
    label: 'PCF',
  },
];
export default definitions;
