<template>
  <div>
    <!-- PROGRAM -->
    <b-row>
      <b-col>
        <b-form-group
          id="input-group-1"
          :label="$t('create.description.program')"
          label-for="input-1"
        >
          <treeselect
            :placeholder="$t('create.description.select')"
            ref="programSelect"
            v-model="treeValue"
            :options="treeOptions"
            :searchable="true"
            :show-count="true"
            :default-expand-level="1"
            :clearable="true"
            @select="handleTreeSelect"
            :flatten-search-results="false"
            :disable-fuzzy-matching="true"
          >
          </treeselect>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- REMARKS -->
    <b-row v-if="program.specialty.uuid != null">
      <b-col>
        <b-form-group
          id="input-group-1"
          :label="$t('create.description.remark')"
          label-for="input-1"
        >
          <el-select
            v-model="remarksValue"
            multiple
            clearable
            filterable
            placeholder="Select"
          >
            <el-option
              v-for="item in remarksOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              clearable
            >
            </el-option>
          </el-select>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- DESCRIPTION -->
    <b-row>
      <b-col>
        <b-form-group
          id="input-group-1"
          :label="$t('create.description.free')"
          label-for="input-1"
        >
          <el-autocomplete
            popper-class="my-autocomplete"
            v-model="description"
            :fetch-suggestions="querySearchDescription"
            :placeholder="$t('create.description.input')"
            @select="handleFavoriteDescriptionSelection"
            :maxlength="200"
            show-word-limit
            clearable
          >
            <i
              for="description"
              id="description"
              class="el-icon-star-off el-input__icon text-warning"
              slot="suffix"
              @click="toggleFavorite"
            >
            </i>
            <template slot-scope="{ item }">
              <div class="value">{{ item }}</div>
            </template>
          </el-autocomplete>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- STANDARDS -->
    <b-row>
      <b-col>
        <b-form-group
          id="input-group-1"
          :label="$t('create.description.standard')"
          label-for="input-1"
        >
          <el-autocomplete
            popper-class="my-autocomplete"
            v-model="standard"
            :fetch-suggestions="querySearchStandard"
            :placeholder="$t('create.description.input')"
            @select="handleFavoriteStandardSelection"
            :maxlength="200"
            show-word-limit
            clearable
          >
            <i
              for="standard"
              id="standard"
              class="el-icon-star-off el-input__icon text-warning"
              slot="suffix"
              @click="toggleFavorite"
            >
            </i>
            <template slot-scope="{ item }">
              <div class="value">{{ item }}</div>
            </template>
          </el-autocomplete>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- CORRECTIVE ACTION -->
    <b-row>
      <b-col>
        <b-form-group
          id="input-group-1"
          :label="$t('create.description.correctiveAction')"
          label-for="input-1"
        >
          <el-autocomplete
            popper-class="my-autocomplete"
            v-model="correctiveAction"
            :fetch-suggestions="querySearchCorrectiveAction"
            :placeholder="$t('create.description.input')"
            @select="handleFavoriteCorrectiveActionSelection"
            :maxlength="200"
            show-word-limit
            clearable
          >
            <i
              for="correctiveAction"
              id="correctiveAction"
              class="el-icon-star-off el-input__icon text-warning"
              slot="suffix"
              @click="toggleFavorite"
            >
            </i>
            <template slot-scope="{ item }">
              <div class="value">{{ item }}</div>
            </template>
          </el-autocomplete>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Commented out as requested -->
    <!-- <LastReports @populate="populateLastReport" /> -->
  </div>
</template>

<script>
import axiosResource from '@/utils/axiosResource';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
// import LastReports from './LastReports.vue'; // Commented out as requested

export default {
  name: 'program',
  components: {
    Treeselect,
    // LastReports, // Commented out as requested
  },
  props: ['report', 'mode'],
  data() {
    return {
      projectUuid: this.$store.getters.currentProject.uuid,
      treeValue: null,
      treeOptions: [],
      program: {
        family: {
          uuid: null,
          label: null,
        },
        activity: {
          uuid: null,
          label: null,
        },
        specialty: {
          uuid: null,
          label: null,
        },
      },
      description: '',
      standard: '',
      correctiveAction: '',
      remarksValue: [],
      remarksOptions: [],
      favorites: {
        descriptions: [],
        standards: [],
        correctiveActions: [],
      },
    };
  },
  mounted() {
    this.getProgram();
    this.fetchFavorites();
    if (this.mode === 'edit' && this.report) {
      this.initializeFromReport();
    }
  },
  watch: {
    report: {
      immediate: true,
      handler(newReport) {
        if (this.mode === 'edit' && newReport) {
          this.initializeFromReport();
        }
      },
    },
    description(val) {
      const star = document.getElementById('description');
      if (this.favorites.descriptions.includes(val)) {
        star.classList.remove('el-icon-star-off');
        star.classList.add('el-icon-star-on');
      } else {
        star.classList.remove('el-icon-star-on');
        star.classList.add('el-icon-star-off');
      }
    },
    standard(val) {
      const star = document.getElementById('standard');
      if (this.favorites.standards.includes(val)) {
        star.classList.remove('el-icon-star-off');
        star.classList.add('el-icon-star-on');
      } else {
        star.classList.remove('el-icon-star-on');
        star.classList.add('el-icon-star-off');
      }
    },
    correctiveAction(val) {
      const star = document.getElementById('correctiveAction');
      if (this.favorites.correctiveActions.includes(val)) {
        star.classList.remove('el-icon-star-off');
        star.classList.add('el-icon-star-on');
      } else {
        star.classList.remove('el-icon-star-on');
        star.classList.add('el-icon-star-off');
      }
    },
  },
  methods: {
    async initializeFromReport() {
      // Determine the value for the tree based on the available data
      if (this.report.specialtyName) {
        this.treeValue = this.report.specialtyName;
        this.program.specialty.uuid = this.report.specialtyUuid;
        this.program.specialty.label = this.report.specialtyName;
        this.program.activity.uuid = this.report.activityUuid;
        this.program.activity.label = this.report.activityName;
        this.program.family.uuid = this.report.familyUuid;
        this.program.family.label = this.report.familyName;

        // Fetching remarks for the selected specialty
        await this.ensureRemarksFetched(
          'specialty_' + this.program.specialty.uuid
        );

        // Log remarksOptions to check if it is populated
        console.log('Remarks options:', this.remarksOptions);

        // Ensure remarksOptions is populated before proceeding
        if (this.remarksOptions.length === 0) {
          console.error('Remarks options not populated in time');
          return;
        }

        // Parse remarkUuids string to array
        let remarkUuids = [];
        try {
          remarkUuids = JSON.parse(this.report.remarkUuids);
          console.log('Parsed remarkUuids:', remarkUuids);
        } catch (error) {
          console.error('Failed to parse remarkUuids:', error);
        }

        // Map remarkUuids to corresponding labels
        this.remarksValue = remarkUuids
          .map((uuid) => {
            const remark = this.remarksOptions.find(
              (option) => option.value === uuid
            );
            return remark ? remark.value : null;
          })
          .filter((value) => value !== null);

        // Log the final remarksValue to verify it is set correctly
        console.log('Remarks value:', this.remarksValue);
      } else if (this.report.activityName) {
        this.treeValue = this.report.activityName;
        this.program.activity.uuid = this.report.activityUuid;
        this.program.activity.label = this.report.activityName;
        this.program.family.uuid = this.report.familyUuid;
        this.program.family.label = this.report.familyName;
        this.remarksValue = []; // No remarks to show if only activity is available
      } else if (this.report.familyName) {
        this.treeValue = this.report.familyName;
        this.program.family.uuid = this.report.familyUuid;
        this.program.family.label = this.report.familyName;
        this.remarksValue = []; // No remarks to show if only family is available
      }

      // Setting the description, standard, and corrective action
      this.description = this.report.description;
      this.standard = this.report.standard;
      this.correctiveAction = this.report.correctiveAction;
    },

    async getRemarks(val) {
      const specialtyUuid = val.split('_')[1];
      try {
        const response = await axiosResource.get(
          `/admin/categories/remarks/${specialtyUuid}`
        );
        if (!response.data.success) {
          this.$globalSwal.error('error.sorry', response.data.message);
        } else {
          this.remarksOptions = response.data.results;
          console.log('Fetched remarks:', this.remarksOptions); // Ensure data is fetched
        }
      } catch (err) {
        this.$globalSwal.error(err.response.data.msg);
      }
    },

    async ensureRemarksFetched(val, retries = 5, delay = 500) {
      for (let i = 0; i < retries; i++) {
        await this.getRemarks(val);
        if (this.remarksOptions.length > 0) {
          return; // Data fetched successfully
        }
        await this.sleep(delay); // Wait for a while before retrying
      }
      console.error('Failed to fetch remarks options after multiple attempts');
    },

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    querySearchDescription(queryString, cb) {
      let descriptions = this.favorites.descriptions;
      let results = queryString
        ? descriptions.filter(this.createFilter(queryString))
        : descriptions;
      cb(results);
    },
    querySearchStandard(queryString, cb) {
      let standards = this.favorites.standards;
      let results = queryString
        ? standards.filter(this.createFilter(queryString))
        : standards;
      cb(results);
    },
    querySearchCorrectiveAction(queryString, cb) {
      let correctiveActions = this.favorites.correctiveActions;
      let results = queryString
        ? correctiveActions.filter(this.createFilter(queryString))
        : correctiveActions;
      cb(results);
    },
    createFilter(queryString) {
      return (item) => {
        return item.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    handleFavoriteDescriptionSelection(item) {
      this.description = item;
    },
    handleFavoriteStandardSelection(item) {
      this.standard = item;
    },
    handleFavoriteCorrectiveActionSelection(item) {
      this.correctiveAction = item;
    },
    toggleFavorite(e) {
      const type = e.target.getAttribute('for');
      let status = e.target.classList.contains('el-icon-star-on');
      if (!status) {
        switch (type) {
          case 'description':
            this.addFavorite('description', this.description);
            break;
          case 'standard':
            this.addFavorite('standard', this.standard);
            break;
          case 'correctiveAction':
            this.addFavorite('correctiveAction', this.correctiveAction);
            break;
          default:
            break;
        }
      } else {
        switch (type) {
          case 'description':
            this.removeFavorite('description', this.description);
            break;
          case 'standard':
            this.removeFavorite('standard', this.standard);
            break;
          case 'correctiveAction':
            this.removeFavorite('correctiveAction', this.correctiveAction);
            break;
          default:
            break;
        }
      }
    },
    handleTreeSelect({ id }) {
      const { programSelect } = this.$refs;
      const node = programSelect.getNode(id);

      if (node.level == 0) {
        this.program.family.uuid = this.extractUuid(node.id);
        this.program.family.label = node.label;
        this.program.specialty.uuid = null;
        this.program.specialty.label = null;
      } else if (node.level == 1) {
        this.program.activity.uuid = this.extractUuid(node.id);
        this.program.activity.label = node.label;
        this.program.family.uuid = this.extractUuid(node.ancestors[0].id);
        this.program.family.label = node.ancestors[0].label;
        this.program.specialty.uuid = null;
        this.program.specialty.label = null;
      } else if (node.level == 2) {
        this.program.specialty.uuid = this.extractUuid(node.id);
        this.program.specialty.label = node.label;
        this.program.activity.uuid = this.extractUuid(node.ancestors[0].id);
        this.program.activity.label = node.ancestors[0].label;
        this.program.family.uuid = this.extractUuid(
          node.ancestors[0].ancestors[0].id
        );
        this.program.family.label = node.ancestors[0].ancestors[0].label;
        this.getRemarks(node.id);
      }
    },
    extractUuid(s) {
      return s.split('_')[1];
    },
    getProgram() {
      axiosResource
        .get(`/admin/categories/all/accessLess/${this.projectUuid}/false`)
        .then((response) => {
          if (!response.data.success) {
            this.$globalSwal.error('error.sorry', response.data.message);
          } else {
            this.treeOptions = response.data.results;
          }
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    getRemarks(val) {
      const clgStyle = 'color: green;font-size: 20px;';
      console.log('%c' + 'getRemakrs()', clgStyle);
      const specialtyUuid = val.split('_')[1];
      axiosResource
        .get(`/admin/categories/remarks/${specialtyUuid}`)
        .then((response) => {
          if (!response.data.success) {
            this.$globalSwal.error('error.sorry', response.data.message);
          } else {
            this.remarksOptions = response.data.results;
            console.log('my remarks now', this.remarksOptions);
          }
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    fetchFavorites() {
      axiosResource
        .get('/favorites/')
        .then((response) => {
          this.favorites.descriptions = response.data.results.descriptions;
          this.favorites.standards = response.data.results.standards;
          this.favorites.correctiveActions =
            response.data.results.correctiveActions;
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    removeFavorite(type, value) {
      axiosResource
        .delete(`/favorites/${type}`, { data: { value } })
        .then((response) => {
          if (response.data.success) {
            this.$message({
              message: this.$t('favorite.removed'),
              type: 'info',
            });
            const star = document.getElementById(type);
            star.classList.remove('el-icon-star-on');
            star.classList.add('el-icon-star-off');
            this.fetchFavorites();
          }
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    addFavorite(type, value) {
      if (value == '') {
        this.$message({
          message: this.$t('favorite.notNull'),
          type: 'warning',
        });
        return;
      }
      axiosResource
        .post(`/favorites/${type}`, { value })
        .then(() => {
          this.$message({
            message: this.$t('favorite.added'),
            type: 'success',
          });
          const star = document.getElementById(type);
          star.classList.remove('el-icon-star-off');
          star.classList.add('el-icon-star-on');
          this.fetchFavorites();
        })
        .catch((err) => {
          this.$message.error(this.$t('favorite.error'));
          this.$globalSwal.error(err.response.data.msg);
        });
    },
  },
};
</script>

<style scoped>
.el-select {
  width: 100%;
}
.el-date-editor.el-input {
  width: 100% !important;
}
.el-autocomplete {
  width: 100%;
}
.el-icon-star-on {
  font-size: 25px !important;
  cursor: pointer;
}
.el-icon-star-off:before {
  font-size: 20px !important;
  cursor: pointer;
}
</style>
<style lang="scss">
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .value {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .link {
      font-size: 12px;
      color: #b4b4b4;
    }
  }
}

.vue-treeselect__menu {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  border-color: rgb(228, 231, 237) !important;
  border-top-style: solid !important;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%) !important;
}
.vue-treeselect__label {
  font-size: 14px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  color: #606266 !important;
}
.vue-treeselect__label.active {
  color: #409eff !important;
}
.vue-treeselect__option--selected > .vue-treeselect__label {
  color: #409eff !important;
}
.vue-treeselect__input {
  color: #606266 !important;
}
.vue-treeselect__menu-container {
  z-index: 2111 !important;
  margin-top: 12px !important;
}
.el-input--suffix .el-input__inner {
  padding-right: 106px;
}
</style>
