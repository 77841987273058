<template>
  <div>
    <div class="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4 ml-auto mr-auto mt-5">
      <div class="row">
        <div class="col-8 mx-auto text-center">
          <img
            :src="require('@/assets/img/logo/logo_goso_identity_dark_84x84.png')"
            alt="Logo Goso"
            class="img-fluid mb-5"
          />
          <h1 class="mt-4">{{ $t('maintenance.title') }}</h1>
          <p>{{ $t('maintenance.text') }}</p>

        </div>
      </div>
    </div>

  </div>
</template>
  
  <script>
export default {
  name: 'Maintenance',
};
</script>
  
  <style scoped>
  /* Your styles here */
  </style>
  