<template>
  <thead style="z-index: 11;">
    <tr>
      <th
        class="header"
        scope="col"
      >
        <b-form-checkbox
          id="checkbox-all"
          v-model="allSelected"
          name="checkbox-all"
          value="true"
          unchecked-value="false"
          class="margin-bottom-10"
          @change="checkboxChangeAll"
        >
        </b-form-checkbox>
      </th>
      <th
        class="align-middle min-width-80"
        scope="col"
        id="id"
        :class="cols.id"
      >ID
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            @click="sortAscending"
          ></i>
          <i
            class="sort-caret descending"
            @click="sortDescending"
          ></i>
        </span>
      </th>
      <!-- <th
        class="align-middle"
        scope="col"
        id="interventionType"
        style="min-width: 100px;"
        :class="cols.type"
      >TYPE
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            @click="sortAscending"
          ></i>
          <i
            class="sort-caret descending"
            @click="sortDescending"
          ></i>
        </span>
      </th> -->
      <th
        class="align-middle min-width-100"
        scope="col"
        id="result"
        :class="cols.result"
      >{{ $t('explore.tableHeader.result')}}
        <span class="caret-wrapper">
          <i class="sort-caret ascending"></i>
          <i class="sort-caret descending"></i>
        </span>
      </th>
      <th
        class="align-middle min-width-110"
        scope="col"
        id="status"
        :class="cols.status"
      >{{ $t('explore.tableHeader.status')}}
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            @click="sortAscending"
          ></i>
          <i
            class="sort-caret descending"
            @click="sortDescending"
          ></i>
        </span>
      </th>
      <th
        class="align-middle min-width-100"
        scope="col"
        id="di"
        :class="cols.di"
      >WI
        <span class="caret-wrapper">
          <i class="sort-caret ascending"></i>
          <i class="sort-caret descending"></i>
        </span>
      </th>
      <!-- <th class="header"
        scope="col"
        id="addressedTo"
        :class="cols.addressedTo"
        style="min-width: 140px;"
      >Addressé à
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            @click="sortAscending"
          ></i>
          <i
            class="sort-caret descending"
            @click="sortDescending"
          ></i>
        </span>
      </th> -->
      <th
        class="align-middle min-width-180"
        scope="col"
        id="buildingName"
        :class="cols.building"
      >{{ $t('explore.tableHeader.reference')}}
        <span class="caret-wrapper">
          <i class="sort-caret ascending"></i>
          <i class="sort-caret descending"></i>
        </span>
      </th>
      <!-- <th class="header"
        scope="col"
        id="level"
        :class="cols.level"
        style="min-width: 80px;"
      >Niv.
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            @click="sortAscending"
          ></i>
          <i
            class="sort-caret descending"
            @click="sortDescending"
          ></i>
        </span>
      </th> -->
      <!-- <th class="header"
        scope="col"
        id="zone"
        :class="cols.zone"
        style="min-width: 80px;"
      >Zo.
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            @click="sortAscending"
          ></i>
          <i
            class="sort-caret descending"
            @click="sortDescending"
          ></i>
        </span>
      </th> -->
      <!-- <th class="header"
        scope="col"
        id="serial"
        :class="cols.serial"
        style="min-width: 100px;"
      >Serial
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            @click="sortAscending"
          ></i>
          <i
            class="sort-caret descending"
            @click="sortDescending"
          ></i>
        </span>
      </th> -->
      <!-- <th class="header"
        scope="col"
        id="definition"
        :class="cols.definition"
        style="min-width: 100px;"
      >Def.
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            @click="sortAscending"
          ></i>
          <i
            class="sort-caret descending"
            @click="sortDescending"
          ></i>
        </span>
      </th> -->
      <th
        class="align-middle min-width-300 max-width-400"
        scope="col"
        id="program"
        :class="cols.program"
      >{{ $t('explore.tableHeader.program')}}
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            @click="sortAscending"
          ></i>
          <i
            class="sort-caret descending"
            @click="sortDescending"
          ></i>
        </span>
      </th>
      <th
        class="align-middle min-width-360"
        scope="col"
        id="description"
        :class="cols.description"
      >{{ $t('explore.tableHeader.description')}}
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            @click="sortAscending"
          ></i>
          <i
            class="sort-caret descending"
            @click="sortDescending"
          ></i>
        </span>
      </th>

      <th
        class="align-middle min-width-180"
        scope="col"
        id="interventionDate"
        :class="cols.interventionDate"
      >{{ $t('explore.tableHeader.date')}}
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            @click="sortAscending"
          ></i>
          <i
            class="sort-caret descending"
            @click="sortDescending"
          ></i>
        </span>
      </th>
      <th
        class="align-middle min-width-180"
        scope="col"
        id="description"
        :class="cols.interventionDate"
      >{{ $t('explore.tableHeader.by')}}
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            @click="sortAscending"
          ></i>
          <i
            class="sort-caret descending"
            @click="sortDescending"
          ></i>
        </span>
      </th>
      <th
        class="align-middle min-width-180"
        scope="col"
        id="description"
        :class="cols.interventionDate"
      >{{ $t('explore.tableHeader.entity')}}
        <span class="caret-wrapper">
          <i
            class="sort-caret ascending"
            @click="sortAscending"
          ></i>
          <i
            class="sort-caret descending"
            @click="sortDescending"
          ></i>
        </span>
      </th>
    </tr>
  </thead>
</template>
<script>
export default {
  name: 'table-header',
  data() {
    return {
      idCol: '',
      typeCol: '',
      resCol: '',
      statusCol: '',
      cols: {
        id: '',
        type: '',
        result: '',
        status: '',
        di: '',
        addressedTo: '',
        building: '',
        level: '',
        zone: '',
        serial: '',
        definition: '',
        program: '',
        description: '',
        interventionDate: '',
      },
      currentSortedColumn: '',
      sorting: {
        column1: {
          none: true,
          asc: false,
          dsc: false,
        },
      },
    };
  },
  computed: {
    allSelected: {
      set() {
        return this.$store.getters.selectedReports.length ==
          this.$store.getters.qtt
          ? true
          : false;
      },
      get() {
        return this.$store.getters.selectedReports.length ==
          this.$store.getters.qtt
          ? true
          : false;
      },
    },
  },
  watch: {
    idCol: function () {
      console.log('Change all other columns to nothing');
    },
  },
  methods: {
    checkboxChangeAll() {
      if (this.allSelected) {
        console.log('Deleting selection...');
        this.$store.commit('deleteSelection');
        console.log(
          'Current state:',
          this.$store.state.selectedReports,
          this.$store.state.selectedReportsData
        );
      } else {
        console.log('Adding to selection...');
        const allReports = this.$store.getters.reports;
        console.log('allReports.length', allReports.length);
        const allUuids = allReports.map((report) => report.uuid);
        this.$store.commit('addToSelection', allUuids);
        allReports.forEach((report) => {
          this.$store.commit('selectReportData', report);
        });
        console.log(
          'Current state:',
          this.$store.state.selectedReports,
          this.$store.state.selectedReportsData
        );
      }
    },
    resetAllSorting() {
      const cols = JSON.parse(JSON.stringify(this.cols));
      console.log(cols);

      for (const [key, value] of Object.entries(cols)) {
        console.log(`${key}: ${value}`);
        this.cols[key] = '';
      }
    },
    sortAscending(e) {
      const clgStyle = 'color: pink;';
      console.log('%c' + 'sortAscending(e)', clgStyle);
      this.resetAllSorting();
      const id = e.target.parentElement.parentElement.id;
      this.currentSortedColumn = id;
      this.cols[id] = 'ascending';
      // Set in store and refresh
      this.$store.commit('setSorting', { column: id, order: 'ASC' });
      this.$store.commit('initPage');
      this.$store.commit('initReports', 'TableHeader');
      this.$store.commit('resetQttLoaded');
      this.$store.dispatch('fetchReports');
    },
    sortDescending(e) {
      const clgStyle = 'color: pink;';
      console.log('%c' + 'sortDescending(e)', clgStyle);
      this.resetAllSorting();
      const id = e.target.parentElement.parentElement.id;
      this.currentSortedColumn = id;
      this.cols[id] = 'descending';
      // Set in store and refresh
      this.$store.commit('setSorting', { column: id, order: 'DESC' });
      this.$store.commit('initPage');
      this.$store.commit('initReports', 'TableHeader');
      this.$store.commit('resetQttLoaded');
      this.$store.dispatch('fetchReports');
    },
  },
};
</script>
<style lang="scss">
// .el-table thead {
//   border-bottom: 1px solid magenta !important;
// }
// .el-table__header {
//   border-bottom: 1px solid black !important;
// }
.table-explore tr {
  height: 56px;
}
.table-explore td {
  vertical-align: middle;
}
.table-explore thead th,
.tabtable-explorele th {
  color: #525f7f;
  border: none !important;
  font-weight: 400;
  padding-top: 0.65rem !important;
  padding-bottom: 0.15rem !important;
}
// .table th,
// .table td {
//   padding-top: 0.05rem !important;
//   padding-bottom: 0.15rem !important;
// }
</style>
<style scoped>
.sort {
  cursor: pointer;
}
.el-table .caret-wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    height: 34px;
    width: 24px;
    vertical-align: middle;
    cursor: pointer;
    overflow: initial;
    position: relative;
}
.sort-caret {
    width: 0;
    height: 0;
    border: 5px solid transparent;
    position: absolute;
    left: 7px;
}
.sort-caret.ascending {
    border-bottom-color: #c0c4cc;
    top: 5px;
}
.ascending .sort-caret.ascending {
    border-bottom-color: #409eff;
}
.el-table .sort-caret.descending {
    border-top-color: #c0c4cc;
    bottom: 7px;
}
.descending .sort-caret.descending {
    border-top-color: #409eff;
}
i {
    font-style: italic;
}

.header {
      position: sticky;
      top:0;
  }
</style>
