<template>
  <div>
    <b-card class="mb-3 mt-3 card-goso-stats height-600">
      <CategoryDrilldown />
    </b-card>
  </div>
</template>
<script>
import CategoryDrilldown from './Program/CategoryDrilldown.vue';

export default {
  name: 'program-view',
  components: {
    CategoryDrilldown,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.card-body {
  padding: 0 !important;
}
</style>

