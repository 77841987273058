<template>
  <b-row>
    <b-col
      cols="12"
      md="12"
      lg="12"
      class="mt-4 mb-4 col-for-card height-700"
    >
    </b-col>
  </b-row>
</template>
<script>
import swal from 'sweetalert2';

const swalBootsrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export default {
  data() {
    return {
      show: true,
    };
  },
  beforeMount() {
    swalBootsrap
      .fire({
        title: this.$t('logout.question'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          swalBootsrap
            .fire({
              icon: 'success',
              title: this.$t('logout.greetings'),
              showConfirmButton: false,
              timer: 1500,
            })
            .then(() => {
              this.$router.push('/auth/login');
            });
          // Set logged OUT in store
          this.$store.commit('logIn', false);
        } else {
          result.dismiss === swal.DismissReason.cancel;
          this.$router.go(-1);
        }
      });
  },
  methods: {
    logout() {
      swalBootsrap
        .fire({
          title: this.$t('logout.question'),
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            swalBootsrap
              .fire({
                icon: 'success',
                title: this.$t('logout.greetings'),
                showConfirmButton: false,
                timer: 1500,
              })
              .then(() => {
                this.$router.push('/auth/login');
              });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === swal.DismissReason.cancel
          ) {
            // Do nothing
          }
        });
    },
  },
};
</script>
<style scoped>
.height-700 {
  height:700px;
}
</style>