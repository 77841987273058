<template>
  <b-modal
    id="modal-address"
    :title="$t('modal.address.title')"
    button-size="sm"
    @hide="clearFields"
  >
    <p>{{ $t('modal.address.selectEntity') }}</p>
    <form ref="form">
      <b-form-group class="mb-4">
        <el-select
          v-model="form.entityUuid"
          @blur="$v.form.entityUuid.$touch"
          filterable
          clearable=""
          :placeholder="$t('filter.select')"
          class="w-100"
          @change="fetchUsersInEntity"
        >
          <el-option
            v-for="item in entitiesOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <label
          class="error"
          v-if="!$v.form.entityUuid.required && $v.form.entityUuid.$dirty"
        >{{ $t('validate.required') }}
        </label>
      </b-form-group>

      <p
        class="mt-3 mb-4"
        v-if="form.entityUuid"
      >
        <el-switch v-model="form.byEmail">
        </el-switch> {{ $t('modal.address.byEmail') }}
      </p>

      <p v-if="form.byEmail">{{ $t('modal.address.selectUser') }}</p>

      <b-form-group
        class="mb-4"
        v-if="form.byEmail"
      >
        <el-select
          v-model="form.toUserUuid"
          @blur="$v.form.toUserUuid.$touch"
          filterable
          clearable=""
          :placeholder="$t('filter.select')"
          class="w-100"
        >
          <el-option
            v-for="item in usersOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <label
          class="error"
          v-if="!$v.form.toUserUuid.required && $v.form.toUserUuid.$dirty"
        >{{ $t('validate.required') }}
        </label>
      </b-form-group>

      <el-input
        type="textarea"
        class="mb-3"
        :rows="2"
        :placeholder="$t('modal.address.customInstruction')"
        v-model="form.instruction"
        v-if="form.byEmail"
        :autosize="{ minRows: 3, maxRows: 4}"
        maxlength="100"
        show-word-limit
      ></el-input>
    </form>
    <template #modal-footer="{ hide }">
      <b-button
        size="sm"
        variant="primary"
        @click="address()"
      >
        {{ $t('action.confirm') }}
      </b-button>
      <b-button
        size="sm"
        variant="light"
        @click="hide()"
      >
        {{ $t('action.close') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import axiosResource from '@/utils/axiosResource';
import { required, requiredIf } from 'vuelidate/lib/validators';
import swal from 'sweetalert2';
const swalBootsrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export default {
  name: 'modal-address',
  data() {
    return {
      usersOptions: [],
      entitiesOptions: [],
      form: {
        entityUuid: '',
        toUserUuid: '',
        instruction: '',
        byEmail: false,
      },
    };
  },
  validations: {
    form: {
      entityUuid: { required },
      toUserUuid: {
        required: requiredIf(function () {
          return this.form.byEmail;
        }),
      },
    },
  },
  mounted() {
    this.fetchEntities();
    // this.fetchUsers();
  },
  watch: {
    'form.byEmail': function (newValue) {
      // Clear specific form fields when byEmail is changed
      this.form.toUserUuid = '';
      this.form.instruction = '';

      // Reset validation state
      this.$nextTick(() => {
        this.$v.form.$reset();
      });
    },
  },
  methods: {
    clearFields() {
      this.form.toUserUuid = '';
      this.form.instruction = '';
      this.form.byEmail = false;
      this.form.entityUuid = '';
      this.$v.form.$reset();
    },
    address() {
      this.$store.commit('loading', false); // temporaty LINE TO BE DELETED
      const projectUuid = this.$store.getters.currentProject.uuid;
      const projectRef = this.$store.getters.currentProject.ref;
      const selectedReports = this.$store.getters.selectedReports;
      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        this.submitStatus = 'ERROR';
      } else {
        this.$store.commit('loading', true);
        const clgStyle = 'color: red;';

        // Send request
        axiosResource
          .post(`/reports/address/${projectUuid}`, {
            data: {
              form: this.form,
              projectRef,
              selectedReports,
            },
          })
          .then((response) => {
            this.$store.commit('loading', false);
            this.$bvModal.hide('modal-address');
            if (!response.data.success) {
              this.$globalSwal.error('error.sorry', response.data.message);
            } else {
              this.$globalSwal.success('success.done', response.data.message);
              this.$store.dispatch('changeStatus', 'ADDRESSED');
            }
          })
          .catch((err) => {
            this.$store.commit('loading', false);
            console.log(err);
            this.$globalSwal.error(err.response.data.msg);
            // this.$globalSwal.serverError();
            // if (err.response && err.response.status === 500) {
            //   this.$globalSwal.error(
            //     'error.oops',
            //     err.response.data.message,
            //     'error.persists'
            //   );
            // }
          });

        this.submitStatus = 'PENDING';
        setTimeout(() => {
          this.submitStatus = 'OK';
        }, 500);
      }
    },
    fetchEntities() {
      axiosResource
        .get('/admin/entities/accessLess')
        .then((response) => {
          const clgStyle = 'color: red;';
          console.log('%c' + 'ENTITIES', clgStyle);
          const entitiesObj = response.data;
          console.log(entitiesObj);
          entitiesObj.forEach((element) => {
            this.entitiesOptions.push({
              value: `${element.entity.uuid}`,
              label: `${element.entity.name}`,
            });
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    fetchUsersInEntity() {
      if (!this.form.entityUuid) {
        this.usersOptions = []; // Clear users if no entity is selected
        return;
      }
      axiosResource
        .get(`/admin/entities/${this.form.entityUuid}/users`)
        .then((response) => {
          this.usersOptions = []; // Clear previous users before setting new ones
          const usersObj = response.data.results; // assuming the API returns a 'results' array

          usersObj.forEach((element) => {
            let label;
            if (element.status !== 'ACTIVE') {
              label = element.name.acronym4digits;
            } else {
              label = element.name.complete;
            }
            this.usersOptions.push({
              value: `${element.uuid}`,
              label: label,
            });
          });
        })
        .catch((err) => {
          this.usersOptions = []; // Also clear users on error to avoid displaying incorrect data
          console.error('Error fetching users:', err);
          this.$globalSwal.error(
            'Failed to fetch users',
            err.response.data.msg
          );
        });
    },
    fetchUsers() {
      axiosResource
        .get('/admin/users/accessLess')
        .then((response) => {
          const clgStyle = 'color: red;';
          console.log('%c' + 'USERS', clgStyle);
          const usersObj = response.data;
          console.log(usersObj);
          usersObj.forEach((element) => {
            this.usersOptions.push({
              value: `${element.uuid}_${element.username}`,
              label: `${element.name.complete}`,
            });
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$globalSwal.error(err.response.data.msg);
        });
    },
  },
};
</script>