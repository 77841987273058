<template>
  <div class="chart-container">
    <highcharts
      :options="chartOptions"
      ref="highcharts"
    ></highcharts>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';

export default {
  name: 'detailed',
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'column',
          height: '100%',
        },
        title: {
          text: this.$t('highcharts.detailedInteractions'),
          align: 'left',
          style: { fontSize: '16px' },
        },
        subtitle: {
          text: this.$t('highcharts.overPeriod'),
          align: 'left',
          style: { fontSize: '10px' },
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: ['Detailed reports'], // You can keep the categories if needed
          title: {
            text: '', // Empty title
          },
          labels: {
            enabled: false, // Hide labels
          },
          tickLength: 0, // Hide tick marks
        },
        yAxis: {
          title: {
            text: '',
          },
        },
        plotOptions: {
          column: {
            dataLabels: {
              enabled: true,
            },
            showInLegend: true,
          },
        },
        accessibility: {
          enabled: false,
        },
        series: [
          {
            name: this.$t('priority.info.value'),
            data: [this.$store.getters.chart.detailedInteractions.INFO],
            color: 'rgba(146, 146, 146, 0.8)',
          },
          {
            name: this.$t('priority.longterm.value'),
            data: [this.$store.getters.chart.detailedInteractions.LONGTERM],
            color: 'rgba(0, 123, 255, 0.8)',
          },
          {
            name: this.$t('priority.mediumterm.value'),
            data: [this.$store.getters.chart.detailedInteractions.MEDIUMTERM],
            color: 'rgba(255, 255, 0, 0.8)',
          },
          {
            name: this.$t('priority.shortterm.value'),
            data: [this.$store.getters.chart.detailedInteractions.SHORTTERM],
            color: 'rgba(255, 193, 7, 0.8)',
          },
          {
            name: this.$t('priority.urgent.value'),
            data: [this.$store.getters.chart.detailedInteractions.URGENT],
            color: 'rgba(220, 53, 69, 0.8)',
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                {
                  text: this.$t('highcharts.viewFullscreen'),
                  onclick: function () {
                    this.series[0].chart.fullscreen.toggle();
                  },
                },
                'separator',
                {
                  text: this.$t('highcharts.downloadPNG'),
                  onclick: function () {
                    this.exportChart({
                      type: 'image/png',
                    });
                  },
                },
              ],
            },
          },
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                chart: {
                  width: null,
                },
              },
            },
          ],
        },
      },
    };
  },
  watch: {
    changing: function () {
      this.redraw();
    },
  },
  computed: {
    changing: function () {
      return [
        this.$store.getters.chart.detailedInteractions.INFO,
        this.$store.getters.chart.detailedInteractions.LONGTERM,
        this.$store.getters.chart.detailedInteractions.MEDIUMTERM,
        this.$store.getters.chart.detailedInteractions.SHORTTERM,
        this.$store.getters.chart.detailedInteractions.URGENT,
      ];
    },
  },
  mounted() {
    this.$store.dispatch('fetchStats', 'detailedInteractions');
    window.addEventListener('resize', this.resizeChart);
    this.$nextTick(() => {
      this.resizeChart(); // Ensure chart resizes correctly on initial load
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeChart);
  },
  methods: {
    redraw() {
      const data = [
        {
          name: this.$t('priority.info.value'),
          data: [this.$store.getters.chart.detailedInteractions.INFO],
          color: 'rgba(146, 146, 146, 0.8)',
        },
        {
          name: this.$t('priority.longterm.value'),
          data: [this.$store.getters.chart.detailedInteractions.LONGTERM],
          color: 'rgba(0, 123, 255, 0.8)',
        },
        {
          name: this.$t('priority.mediumterm.value'),
          data: [this.$store.getters.chart.detailedInteractions.MEDIUMTERM],
          color: 'rgba(255, 255, 0, 0.8)',
        },
        {
          name: this.$t('priority.shortterm.value'),
          data: [this.$store.getters.chart.detailedInteractions.SHORTTERM],
          color: 'rgba(255, 193, 7, 0.8)',
        },
        {
          name: this.$t('priority.urgent.value'),
          data: [this.$store.getters.chart.detailedInteractions.URGENT],
          color: 'rgba(220, 53, 69, 0.8)',
        },
      ];
      this.chartOptions.series = data;
    },
    resizeChart() {
      if (this.$refs.highcharts) {
        this.$refs.highcharts.chart.reflow();
      }
    },
  },
};
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
}
</style>
