<template>
  <b-modal
    id="modal-image"
    ref="modalImage"
    title="Image Preview"
    size="md"
    button-size="sm"
    hide-footer
    class="custom-modal"
  >
    <b-carousel
      v-if="report"
      :interval="0"
      :controls="shouldShowIndicators"
      :indicators="shouldShowIndicators"
      class="custom-carousel"
    >
      <b-carousel-slide
        v-if="report.presignedUrlPhoto1"
        :img-src="report.presignedUrlPhoto1"
      ></b-carousel-slide>
      <b-carousel-slide
        v-if="report.presignedUrlPhoto2"
        :img-src="report.presignedUrlPhoto2"
      ></b-carousel-slide>
      <b-carousel-slide
        v-if="report.presignedUrlPhoto3"
        :img-src="report.presignedUrlPhoto3"
      ></b-carousel-slide>
    </b-carousel>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalImage',
  props: {
    report: {
      type: Object,
      default: null,
    },
  },
  computed: {
    shouldShowIndicators() {
      let imageCount = 0;
      if (this.report) {
        if (this.report.presignedUrlPhoto1) imageCount++;
        if (this.report.presignedUrlPhoto2) imageCount++;
        if (this.report.presignedUrlPhoto3) imageCount++;
      }
      return imageCount > 1;
    },
  },
  methods: {
    show() {
      this.$refs.modalImage.show();
    },
  },
};
</script>

<style scoped>
.custom-modal .modal-dialog {
  max-height: calc(100vh - 20px);
  display: flex;
  flex-direction: column;
}

.custom-modal .modal-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.custom-modal .modal-body {
  flex: 1;
  overflow-y: auto;
}

.custom-carousel {
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-carousel img {
  max-height: 80vh;
  object-fit: contain;
  width: auto;
  margin: auto;
  display: block;
}

 

</style>
