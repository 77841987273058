<template>
  <b-modal
    id="modal-favorite"
    :title="$t('modal.flag.title')"
    button-size="lg"
    @show="onShow"
  >
    <form
      ref="form"
      @submit.prevent="saveFavorite"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group :label="$t('favorite.modal.description') + '*'">
            <b-form-input
              type="text"
              class="form-control"
              id="name"
              v-model="form.favoriteText"
              @blur="$v.form.favoriteText.$touch"
            ></b-form-input>
            <label
              class="error"
              v-if="!$v.form.favoriteText.required && $v.form.favoriteText.$dirty"
            >{{ $t('validate.required') }}
            </label>
            <label
              class="error"
              v-if="!$v.form.favoriteText.maxLength && $v.form.favoriteText.$dirty"
            >{{ $t('validate.maxLength', { val: '100' }) }}</label>
            <label
              class="error"
              v-if="!$v.form.favoriteText.minLength && $v.form.favoriteText.$dirty"
            >{{ $t('validate.minLength', { val: '1' }) }}</label>
          </b-form-group>
        </b-col>
      </b-row>
    </form>
    <template #modal-footer="{ hide }">
      <b-button
        size="sm"
        variant="primary"
        @click="saveFavorite"
      >
        {{ $t('action.save') }}
      </b-button>
      <b-button
        size="sm"
        variant="light"
        @click="hide"
      >
        {{ $t('action.close') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import axiosResource from '@/utils/axiosResource';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { globalSwal } from '@/plugins/globalSwal';
import swal from 'sweetalert2';
const swalBootsrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export default {
  name: 'modal-favorite',
  props: {
    type: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    scope: {
      type: String,
      default: 'new',
    },
  },
  data() {
    return {
      form: {
        favoriteText: '',
      },
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.form.favoriteText = newValue;
      },
    },
  },
  validations: {
    form: {
      favoriteText: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(100),
      },
    },
  },
  methods: {
    onShow() {
      if (this.scope === 'new') {
        this.form.favoriteText = '';
      } else {
        this.form.favoriteText = this.value;
      }
      this.$v.$reset();
    },
    saveFavorite() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      const method = this.scope === 'edit' ? 'put' : 'post';
      const url = `/favorites/${this.type}`;
      const data =
        this.scope === 'edit'
          ? { oldValue: this.value, newValue: this.form.favoriteText }
          : { value: this.form.favoriteText };

      axiosResource[method](url, data)
        .then(() => {
          this.$emit('favorite-saved');
          this.$bvModal.hide('modal-favorite');
        })
        .catch((err) => {
          this.$message.error(this.$t('favorite.error'));
          this.$globalSwal.error(err.response.data.msg);
        });
    },
  },
};
</script>
