<template>
  <b-row class="mb-1">
    <b-col cols="3">
      <b-badge
        pill
        variant="light"
      >
        <font-awesome-layers>
          <font-awesome-icon
            icon="fa-light fa-square"
            transform="grow-6 rotate-45"
          />
          <font-awesome-layers-text
            class="gray8"
            transform="shrink-6"
            value="WI"
          />
        </font-awesome-layers>
        <span
          :class="iconClass"
          class="history-status"
        >
          {{ wiStatus }}
        </span>
      </b-badge>
    </b-col>
    <b-col
      cols="5"
      class="history-detail"
    >
      {{ wiData }}
    </b-col>
    <b-col
      cols="2"
      class="history-user"
    >
      {{ user }}
    </b-col>
    <b-col
      cols="2"
      class="history-date"
    >
      {{ date }}
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    actionType: String,
    wiData: String,
    user: String,
    date: String,
  },
  computed: {
    wiStatus() {
      return this.actionType === 'WI+'
        ? this.$t('history.added')
        : this.$t('history.removed');
    },
    iconClass() {
      return this.actionType === 'WI+'
        ? 'history-text-success'
        : 'history-text-primary';
    },
  },
};
</script>
