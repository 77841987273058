<template>
  <b-modal
    id="modal-is-center"
    :title="$t('modal.map.title')"
    button-size="sm"
  >
    <p>{{ $t('modal.map.text1') }}</p>
    <p>{{ $t('modal.map.text2') }}</p>
    <p>
      <font-awesome-icon
        icon="fa-duotone fa-lightbulb-on"
        size="lg"
        class="text-warning"
      /> {{ $t('modal.map.text3') }}
    </p>

    <template #modal-footer="{ hide }">
      <b-button
        size="sm"
        variant="light"
        @click="hide()"
      >
        {{ $t('action.close') }}
      </b-button>
    </template>
  </b-modal>

</template>

<script>
export default {
  name: 'modal-is-center',
  data() {
    return {};
  },
};
</script>
<style scoped>
#modal-is-center___BV_modal_outer_ {
  z-index: 2040 !important;
}
</style>
