<template>
  <div class="container-600">
    <div class="stats-navbar d-none d-md-flex">
      <div class="d-inline-flex stats-navbar-container-one">
        <button
          class="filter-button"
          @click="triggerSidebar()"
        >
          <font-awesome-icon
            icon="fa-solid fa-bars-filter filter-icon"
            size="xl"
          />
        </button>
        <div
          class="action-loader"
          v-loading="loading"
        ></div>
      </div>

      <div class="stats-mode">
        <!-- BUTTON GROUP: DESKTOP (or OTHER THAN MOBILE) -->
        <el-button-group>
          <el-button
            class="stats-mode-btn"
            id="global_DESKTOP"
            @click="switchTab"
          >
            <font-awesome-icon
              icon="fa-solid fa-chart-mixed"
              class="mr-2"
            />{{ $t('stats.tab.global') }}
          </el-button>
          <el-button
            class="stats-mode-btn"
            id="program_DESKTOP"
            @click="switchTab"
          >
            <font-awesome-icon
              icon="fa-solid fa-chart-pie"
              class="mr-2"
            />{{ $t('stats.tab.categories') }}
          </el-button>
          <el-button
            class="stats-mode-btn"
            id="curve_DESKTOP"
            @click="switchTab"
          >
            <font-awesome-icon
              icon="fa-solid fa-chart-line"
              class="mr-2"
            />{{ $t('stats.tab.progress') }}
          </el-button>
        </el-button-group>

      </div>
    </div>

    <!-- FILTERS -->
    <div
      id="mySidebar"
      class="filter-sidebar d-none d-sm-block"
      ref="sideBarFilter"
    >
      <Filters purpose='STATS' />
    </div>

    <div id="main">
      <!-- BUTTON GROUP: MOBILE -->
      <el-button-group class="d-block d-sm-none stats-btn-group-mobile">
        <el-button
          class="stats-mode-btn stats-btn-mobile"
          id="global_MOBILE"
          @click="switchTab"
        >
          <font-awesome-icon icon="fa-solid fa-chart-mixed" />
        </el-button>
        <el-button
          class="stats-mode-btn stats-btn-mobile"
          id="program_MOBILE"
          @click="switchTab"
        >
          <font-awesome-icon icon="fa-solid fa-chart-pie" />
        </el-button>
        <el-button
          class="stats-mode-btn stats-btn-mobile"
          id="curve_MOBILE"
          @click="switchTab"
        >
          <font-awesome-icon icon="fa-solid fa-chart-line" />
        </el-button>
      </el-button-group>
      <!-- COMPONENT -->
      <component
        v-bind:is="currentTabComponent"
        class="tab"
      ></component>

    </div>

  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import Filters from '../../components/SideBarFilter.vue';
import GlobalView from '../../components/Stats/GlobalView.vue';
import ProgramView from '../../components/Stats/ProgramView.vue';
import CurveView from '../../components/Stats/CurveView.vue';

export default {
  name: 'stats',
  components: {
    Filters,
    GlobalView,
    ProgramView,
    CurveView,
  },
  data() {
    return {
      sidebarOpen: this.$store.getters.filterSidebar,
      currentTab: '',
      loading: false,
    };
  },
  beforeDestroy() {
    const navbar = document.getElementById('navbar');
    navbar.classList.add('navbar-shadow');
  },
  mounted() {
    const navbar = document.getElementById('navbar');
    navbar.classList.remove('navbar-shadow');
    const { path } = this.$route;
    this.SAVE_CONTEXT(path);

    if (Math.min(window.screen.width, window.screen.height) < 768) {
      const tabBtn_MOBILE = document.getElementById(
        this.$store.getters.currentStatsView + '_MOBILE'
      );
      tabBtn_MOBILE.classList.add('el-btn-selected');
    } else {
      const tabBtn_DESKTOP = document.getElementById(
        this.$store.getters.currentStatsView + '_DESKTOP'
      );
      tabBtn_DESKTOP.classList.add('el-btn-selected');
    }

    if (this.sidebarOpen) {
      this.openNav();
    } else {
      this.closeNav();
    }

    this.fitMobile();
  },
  created() {
    setTimeout(() => {
      this.fitMobile();
    }, 1000);

    window.addEventListener('resize', this.fitMobile);
  },
  destroyed() {
    window.removeEventListener('resize', this.fitMobile);
  },
  computed: {
    currentTabComponent: function () {
      return this.$store.getters.currentStatsView.toLowerCase() + '-view';
    },
  },
  methods: {
    ...mapMutations(['SAVE_CONTEXT']),
    fitMobile() {
      if (window.matchMedia('screen and (max-width: 480px)').matches) {
        // TODO: this is called on scroll for mobiles (add alert to notice)
        // this.$store.commit('setCurrentCard', 'tiny');
        document.getElementById('main').style.marginLeft = '0';
        document.getElementById('main').style.marginRight = '0';
        document.getElementById('main').style.width = '100%';
      }
    },
    isMobile() {
      if (window.matchMedia('screen and (max-width: 768px)').matches) {
        return true;
      }
    },
    triggerSidebar() {
      if (!this.sidebarOpen) {
        console.log('OPEN');
        this.openNav();
      } else {
        console.log('CLOSE');
        this.closeNav();
      }
      this.sidebarOpen = !this.sidebarOpen;
    },
    openNav() {
      this.$store.commit('filterSidebar', true);
      document.getElementById('mySidebar').style.width = '392px';
      document.getElementById('mySidebar').style.opacity = '100';
      document.getElementById('main').style.marginLeft = '392px';
      const width = document.body.offsetWidth - 392;
      document.getElementById('main').style.width = `${width}px`;
      // If on tableView mode
      // if (this.$store.getters.currentCard == 'table') {
      //   document.getElementById('infiniteScrollTable').style.borderLeft =
      //     '1px solid rgb(229, 232, 235)';
      // }
    },
    closeNav() {
      this.$store.commit('filterSidebar', false);
      document.getElementById('mySidebar').style.opacity = '0';
      document.getElementById('mySidebar').style.width = '52px';
      document.getElementById('main').style.marginLeft = '52px';
      const width = document.body.offsetWidth - 104;
      document.getElementById('main').style.width = `${width}px`;
      // If on tableView mode
      // if (this.$store.getters.currentCard == 'table') {
      //   document.getElementById('infiniteScrollTable').style.borderLeft =
      //     'none';
      // }
    },
    switchTab(e) {
      if (Math.min(window.screen.width, window.screen.height) < 768) {
        // MOBILE
        const globalBtn_MOBILE = document.getElementById('global_MOBILE');
        const programBtn_MOBILE = document.getElementById('program_MOBILE');
        const curveBtn_MOBILE = document.getElementById('curve_MOBILE');
        globalBtn_MOBILE.classList.remove('el-btn-selected');
        programBtn_MOBILE.classList.remove('el-btn-selected');
        curveBtn_MOBILE.classList.remove('el-btn-selected');
      } else {
        // NON-MOBILE
        const globalBtn_DESKTOP = document.getElementById('global_DESKTOP');
        const programBtn_DESKTOP = document.getElementById('program_DESKTOP');
        const curveBtn_DESKTOP = document.getElementById('curve_DESKTOP');
        globalBtn_DESKTOP.classList.remove('el-btn-selected');
        programBtn_DESKTOP.classList.remove('el-btn-selected');
        curveBtn_DESKTOP.classList.remove('el-btn-selected');
      }

      // Extract current tab name
      const tab = e.currentTarget.id.split('_');
      this.currentTab = tab[0];

      this.$store.commit('setCurrentStatsView', tab[0]);
      const tabBtn = document.getElementById(e.currentTarget.id);
      tabBtn.classList.add('el-btn-selected');
    },
  },
};
</script>
<style scoped>


.stats-navbar {
    position: fixed;
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0px 52px;
    top: 52px;
    width: 100%;
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 10px;
}
.filter-button {
    background: rgb(255, 255, 255);
    /* display: flex; */
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    /* justify-content: center; */
    width: 42px;
    height: 42px;
    padding: 12px;
    border: none;
    border-radius: 50%;
}
.filter-button:hover {
    transition: all 0.2s ease 0s !important;
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 10px !important;
}


@media (min-width: 1200px) {
  .stats-navbar-container-one {
    width: 360px;
  }
}



.stats-mode {
    /* width: 200px;; */
    /* margin-right: 0; */
    margin-left: auto;
    /* display: flex;
    justify-content: right; */
}
/* Explore mode button grou[ */
.stats-mode-btn {
    border-color: rgb(229, 232, 235) !important;
}
.stats-mode-btn:hover, .stats-mode-btn:focus {
    background: rgba(229, 232, 235, 0.5);
    color: rgb(4, 17, 29);
    border-color:  rgb(229, 232, 235) !important;
}
.el-btn-selected {
  color: rgb(4, 17, 29);
    border-color: rgb(229, 232, 235);
    background-color: rgba(229, 232, 235, 0.5);
}
.el-button {
    border-radius: 12px;
    border-color:  rgb(229, 232, 235) !important;
    /* border: 2px solid; */
}
.el-button:focus, .el-button:hover {
    background: rgba(229, 232, 235, 0.5) !important;
    color: #606266 !important;
    border-color: rgb(229, 232, 235) !important;
}




.el-button-group>.el-button:first-child {
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
    /* border: 2px solid; */
}
.el-button-group>.el-button:not(:first-child):not(:last-child) {
    /* border-top: 2px solid; */
    /* border-bottom: 2px solid; */
}
.el-button-group>.el-button:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    /* border: 2px solid; */
}

.filter-sidebar {
    position: fixed;
    top: 120px;
    /* height: 100%; */
    height: calc(100vh - 120px);
    width: 0;
    z-index: -1;
    left: 0;
    background-color: #fff;
    overflow-x: hidden;
    /* transition:  0.5s; */
    transition: all 0.2s ease 0s !important;
    transition: alpha 0.2s;
    /* padding-top: 150px; */
}


.action-loader { 
    margin-left: 20px;
}

#main {
    width: calc(100% - 392px);
    height: 100vh;
    overflow-y: scroll;
    top: 120px;
    transition: margin-left .5s;
    z-index: -1;
    margin-right: 52px;
    padding-bottom: 200px !important;
    background-color: #ffffff;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
    #main {
        position: fixed;
        padding: 16px;
    }
}


/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidebar {padding-top: 15px;}
  .sidebar a {font-size: 18px;}
}



.stats-btn-group-mobile {
  
  margin-top: 30px;
  margin-left: 15px;
  margin-right: 15px;;
}
.stats-btn-mobile {
  width: calc((100%)/3);
}
</style>