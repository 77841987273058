<template>
  <div>
    <side-bar-menu :menu="$section().home"></side-bar-menu>
    <idle-timer></idle-timer>
    <div
      class="background-container"
      :style="{ backgroundImage: `url(${require(`@/assets/img/bg/${this.$store.getters.currentProject.image}`)})` }"
    >

    </div>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <div class="home-front-container">
          <span class="txt-title">{{ $t('home.textA') }}</span>
          <span class="txt-subtitle">{{ $t('home.textB') }}</span>

          <b-button
            variant="primary"
            class="btn-primary-special mt-5"
            size="lg"
            :to="{path: '/explore'}"
          >{{ $t('home.buttonExplore') }}</b-button>
          <b-button
            variant="light"
            class="btn-light-special ml-4 mt-5"
            size="lg"
            :to="{path: '/scan'}"
          >{{ $t('home.buttonScan') }}</b-button>
          <br><br><br>
          <router-link to="/home/support/helphome">
            <a class="link mt-5">
              <font-awesome-icon
                icon="fa-solid fa-circle-play"
                size="sm"
              /> {{ $t('home.how') }}
            </a>
          </router-link>

        </div>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-card
          :img-src="require(`@/assets/img/bg/${this.$store.getters.currentProject.image}`)"
          img-alt="
          Image"
          img-top
          tag="article"
          class="mb-2 mx-auto"
        >
          <!-- <b-img
            :src="require('@/assets/img/bg/bg-ep-str.jpg')"
            alt="bg-parliament"
            class="img-site"
          >
          </b-img> -->
          <b-card-text>
            <p class="txt-card-title ml-2">{{ this.$store.getters.currentProject.ref }}</p>
            <p class="txt-card-detail ml-2">{{ this.$store.getters.currentProject.projectShortname }} </p>
          </b-card-text>

        </b-card>
      </b-col>
    </b-row>
    <!-- SAFETY MARGIN -->
    <div class="safety-margin"></div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import SideBarMenu from '../../components/SideBarMenu.vue';
import IdleTimer from '../../components/IdleTimer.vue';

import backgroundUrl from '@/assets/img/bg/bg-ep-LUX.jpg';

export default {
  components: {
    SideBarMenu,
    IdleTimer,
  },
  data() {
    return {
      show: false,
      backgroundUrl,
      projectRef: this.$store.getters.currentProject.ref,
      siteFullTemp: '',
    };
  },
  methods: {
    ...mapMutations(['SAVE_CONTEXT']),
    bgImage(ref) {
      return `background-image: url(../../assets/img/bg/bg-ep-LUX.jpg)`;
    },
  },
  mounted() {
    this.show = !this.show;
    const { path } = this.$route;
    this.SAVE_CONTEXT(path);
    switch (this.projectRef) {
      case 'BRU':
        this.siteFullTemp = 'BRUXELLES';
        break;
      case 'STR':
        this.siteFullTemp = 'STRASBOURG';
        break;
      case 'LUX':
        this.siteFullTemp = 'LUXEMBOURG';
        break;
      default:
        break;
    }
    // TEMP: ADD SHADOW
    const navbar = document.getElementById('navbar');
    navbar.classList.add('navbar-shadow');
  },
  computed: {},
};
</script>
<style scoped>
.home-front-container {
  padding: 86px 20px 44px 40px;
}

.background-container {
  width: 100vw;
  height: 566px;
  position: fixed;
  top: -1px;
  left: -1px;
  overflow: hidden;
  z-index: -1 !important;
  /* background-image: url(../../assets/img/bg/bg-ep-LUX.jpg); */
  background-size: cover;
  background-color: rgb(255, 255, 255);
  background-position: center center;
  opacity: 0.8;
  filter: blur(8px);
  -webkit-mask: linear-gradient(rgb(255, 255, 255), transparent);
}
/* .img-site {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    padding: 0;
    border: none;
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
} */

.txt-title {
  font-size: 36px;
  font-weight: 600;
  text-align: left;
  max-width: 100%;
  color: rgb(4, 17, 29);
  display: block;
}
.txt-subtitle {
  margin-top: 18px;
  width: 80%;
  font-size: 24px;
  font-weight: 400;
  text-align: left;
  max-width: 100%;
  color: rgb(4, 17, 29);
  display: block;
}
.card-img, .card-img-top {
    border-top-left-radius: 0.9875rem;
    border-top-right-radius: 0.9875rem;
    
}
.card {
  border-radius: 0.9875rem;
  border: none;
  margin-top: 50px;
  /* max-width: 40rem; */
  width: 90%;
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 10px 0px;
}
.txt-card-title {
  font-weight: 900;
  font-size: 14px;
  color: rgb(53, 56, 64);
  margin-bottom: 0.1rem;
}
.txt-card-detail {
  color: rgb(32, 129, 226);
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 0.1rem;
}
.btn-primary-special {
  width: 167px;
  border-radius: 12px;
  background-color: rgb(32, 129, 226);
  border: 2px solid rgb(32, 129, 226);
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 600;
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 10px 0px;
}
.btn-light-special {
  width: 167px;
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 10px 0px;
  color: rgb(32, 129, 226);
  border-radius: 12px;
  background-color: #ffffff;
  border: 2px solid #ffffff;
  font-size: 16px;
  font-weight: 800;
  background-color: white;
}
.link {
  color: rgb(32, 129, 226);
  font-size: 16px;
  font-weight: 800;
}
</style>