<template>
  <b-modal
    id="modal-comments"
    :title="$t('modal.comments.title')"
    size="lg"
    button-size="sm"
    @show="onShow"
  >
    <b-row
      v-for="comment in comments"
      :key="comment.uuid"
    >

      <b-col
        class="comment-item"
        cols="10"
      >
        <h4>
          <b-badge
            pill
            variant="light"
          ><font-awesome-icon :icon="['fal', 'comment']" /> {{ comment.comment }}</b-badge>
          <span class="userAcronym4">{{ comment.user.acronym4digits }} ({{ comment.dateCreated }})</span>
        </h4>

      </b-col>
      <b-col cols="2">
        <b-link
          v-if="$store.getters.loggedUser.uuid === comment.byUserUuid"
          @click.prevent="deleteComment(comment.uuid)"
          href="#"
          class="font-size-10"
        >{{ $t('comment.delete') }}</b-link>
      </b-col>
    </b-row>
    <br>
    <form @submit.prevent="onFormSubmit">
      <b-row>
        <b-col cols="10">
          <b-form-group>
            <el-input
              class="custom-input"
              :placeholder="$t('modal.comments.instruction')"
              v-model="form.comment"
              maxlength="100"
              show-word-limit
              clearable
              @blur="$v.form.comment.$touch"
              @keyup.enter.prevent="addComment($event)"
            >
            </el-input>

            <label
              class="error"
              v-if="!$v.form.comment.required && $v.form.comment.$dirty"
            >{{ $t('validate.required') }}</label>
            <label
              class="error"
              v-if="!$v.form.comment.maxLength && $v.form.comment.$dirty"
            >{{ $t('validate.maxLength', { val: '200' }) }}</label>
            <label
              class="error"
              v-if="!$v.form.comment.minLength && $v.form.comment.$dirty"
            >{{ $t('validate.minLength', { val: '2' }) }}</label>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-button
            type="button"
            size="sm"
            variant="primary"
            block
            class="custom-button"
            @click="addComment"
          >
            {{ $t('action.add') }}
          </b-button>
        </b-col>
      </b-row>

    </form>

    <template #modal-footer="{ hide }">
      <div class="d-flex justify-content-between align-items-center w-100">
        <small class="text-muted">
          * {{ $t('validate.required') }}
        </small>
        <div>
          <b-button
            size="sm"
            variant="light"
            @click="hide"
          >
            {{ $t('action.cancel') }}
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>

</template>

<script>
import axiosResource from '@/utils/axiosResource';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
// import axiosResource from '@/utils/axiosResource';
import swal from 'sweetalert2';
const swalBootsrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export default {
  name: 'modal-comments',
  data() {
    return {
      reportUuid: '',
      comments: [],
      form: {
        comment: '',
      },
    };
  },
  validations: {
    form: {
      comment: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(200),
      },
    },
  },
  methods: {
    onFormSubmit() {
      this.addComment();
    },
    onShow() {
      this.$v.$reset();
      this.reportUuid = this.$store.getters.selectedReports[0];
      this.comments = [];
      this.fetchComments();
      console.log(this.comment);
    },
    fetchComments() {
      axiosResource
        .get(`comments/${this.reportUuid}`)
        .then((response) => {
          // Reset commments
          this.comments = response.data.comments;
          console.log(response.data.comments);
          // this.$store.dispatch('updateCommentCount', {
          //   reportUuid: this.reportUuid,
          //   count: this.comments.length,
          // });
        })
        .catch((error) => console.error('Failed to load comments', error));
    },
    addComment(event) {
      if (event) event.preventDefault(); // Ensure this is called to prevent form submission

      const projectUuid = this.$store.getters.currentProject.uuid;

      // Check if the form validation state is okay to proceed
      if (this.$v.form.comment.$invalid) {
        this.$v.form.comment.$touch();
      } else {
        axiosResource
          .post(`comments/${projectUuid}/${this.reportUuid}`, {
            comment: this.form.comment,
          })
          .then((response) => {
            this.comments.push(response.data); // Assuming the API returns the added comment
            this.$store.dispatch('incrementCommentCount', this.reportUuid);
            this.fetchComments(); // Refresh the list after adding a new comment
            this.form.comment = ''; // Clear the input field
            this.$v.form.comment.$reset(); // Reset the validation
          })
          .catch((error) => {
            console.error('Failed to add comment', error);
          });
      }
    },
    deleteComment(commentUuid) {
      swalBootsrap
        .fire({
          title: this.$t('comment.sure'),
          text: this.$t('comment.warning'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('comment.yesDelete'),
        })
        .then((result) => {
          if (result.isConfirmed) {
            axiosResource
              .put(`comments/${commentUuid}`)
              .then((response) => {
                this.$store.dispatch('decrementCommentCount', this.reportUuid);
                this.fetchComments(); // Refresh the list after deleting the comment
                swalBootsrap.fire(
                  this.$t('comment.deleted'),
                  this.$t('comment.confirm'),
                  'success'
                );
              })
              .catch((error) => {
                console.error('Failed to delete comment', error);
                swalBootsrap.fire(
                  'Failed!',
                  'Failed to delete the comment: ' + error.message,
                  'error'
                );
              });
          }
        });
    },
    notReady() {
      swalBootsrap.fire({
        title: 'Oops',
        text: this.$t('error.notReady'),
        showConfirmButton: true,
        icon: 'info',
      });
    },
  },
};
</script>
<style scoped>
.userAcronym4 {
  color: gray;
  font-size: 10px;
}
.custom-input {
  height: 36px; /* Adjust this based on your actual input height */
}

.custom-button {
  height: 36px; /* Ensure the button height matches the input */
}
</style>