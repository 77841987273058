<template>
  <div class="chart-container">
    <highcharts
      :options="chartOptions"
      ref="highcharts"
    ></highcharts>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';

export default {
  name: 'quick',
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'column',
          height: '100%',
        },
        title: {
          text: this.$t('highcharts.quickIntactions'),
          align: 'left',
          style: { fontSize: '16px' },
        },
        subtitle: {
          text: this.$t('highcharts.overPeriod'),
          align: 'left',
          style: { fontSize: '10px' },
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: ['Quick interactions'], // You can keep the categories if needed
          title: {
            text: '', // Empty title
          },
          labels: {
            enabled: false, // Hide labels
          },
          tickLength: 0, // Hide tick marks
        },
        yAxis: {
          title: {
            text: '',
          },
        },
        plotOptions: {
          column: {
            dataLabels: {
              enabled: true,
            },
            showInLegend: true,
          },
        },
        accessibility: {
          enabled: false,
        },
        series: [
          {
            name: this.$t('quickInteraction.like'),
            data: [this.$store.getters.chart.quickInteractions.LIKE_ACTIVE],
            color: 'rgba(86, 193, 255, 0.8)',
          },
          {
            name: this.$t('quickInteraction.like'),
            data: [this.$store.getters.chart.quickInteractions.LIKE_INACTIVE],
            color: 'rgba(86, 193, 255, 0.2)',
            borderColor: 'rgba(0, 123, 255, 1)',
            borderWidth: 1,
          },
          {
            name: this.$t('quickInteraction.visited'),
            data: [this.$store.getters.chart.quickInteractions.VISITED_ACTIVE],
            color: 'rgba(0, 0, 0, 0.8)',
          },
          {
            name: this.$t('quickInteraction.visited'),
            data: [
              this.$store.getters.chart.quickInteractions.VISITED_INACTIVE,
            ],
            color: 'rgba(0, 0, 0, 0.2)',
            borderColor: 'rgba(0, 0, 0, 1)',
            borderWidth: 1,
          },
          {
            name: this.$t('quickInteraction.locked'),
            data: [this.$store.getters.chart.quickInteractions.LOCKED_ACTIVE],
            color: 'rgba(255,141,198, 0.8)',
          },
          {
            name: this.$t('quickInteraction.locked'),
            data: [this.$store.getters.chart.quickInteractions.LOCKED_INACTIVE],
            color: 'rgba(255,141,198, 0.2)',
            borderColor: 'rgba(255,141,198, 1)',
            borderWidth: 1,
          },
          {
            name: this.$t('quickInteraction.busy'),
            data: [this.$store.getters.chart.quickInteractions.BUSY_ACTIVE],
            color: 'rgba(66,144,107, 0.8)',
          },
          {
            name: this.$t('quickInteraction.busy'),
            data: [this.$store.getters.chart.quickInteractions.BUSY_INACTIVE],
            color: 'rgba(66,144,107, 0.2)',
            borderColor: 'rgba(66,144,107, 1)',
            borderWidth: 1,
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                {
                  text: this.$t('highcharts.viewFullscreen'),
                  onclick: function () {
                    this.series[0].chart.fullscreen.toggle();
                  },
                },
                'separator',
                {
                  text: this.$t('highcharts.downloadPNG'),
                  onclick: function () {
                    this.exportChart({
                      type: 'image/png',
                    });
                  },
                },
              ],
            },
          },
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                chart: {
                  width: null,
                },
              },
            },
          ],
        },
      },
    };
  },
  watch: {
    changing: function () {
      this.redraw();
    },
  },
  computed: {
    changing: function () {
      return [
        this.$store.getters.chart.quickInteractions.LIKE_ACTIVE,
        this.$store.getters.chart.quickInteractions.LIKE_INACTIVE,
        this.$store.getters.chart.quickInteractions.VISITED_ACTIVE,
        this.$store.getters.chart.quickInteractions.VISITED_INACTIVE,
        this.$store.getters.chart.quickInteractions.LOCKED_ACTIVE,
        this.$store.getters.chart.quickInteractions.LOCKED_INACTIVE,
        this.$store.getters.chart.quickInteractions.BUSY_ACTIVE,
        this.$store.getters.chart.quickInteractions.BUSY_INACTIVE,
      ];
    },
  },
  mounted() {
    this.$store.dispatch('fetchStats', 'quickInteractions');
    window.addEventListener('resize', this.resizeChart);
    this.$nextTick(() => {
      this.resizeChart(); // Ensure chart resizes correctly on initial load
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeChart);
  },
  methods: {
    redraw() {
      const data = [
        {
          name: this.$t('quickInteraction.like'),
          data: [this.$store.getters.chart.quickInteractions.LIKE_ACTIVE],
          color: 'rgba(86, 193, 255, 0.8)',
        },
        {
          name: this.$t('quickInteraction.like'),
          data: [this.$store.getters.chart.quickInteractions.LIKE_INACTIVE],
          color: 'rgba(86, 193, 255, 0.2)',
          borderColor: 'rgba(0, 123, 255, 1)',
          borderWidth: 1,
        },
        {
          name: this.$t('quickInteraction.visited'),
          data: [this.$store.getters.chart.quickInteractions.VISITED_ACTIVE],
          color: 'rgba(0, 0, 0, 0.8)',
        },
        {
          name: this.$t('quickInteraction.visited'),
          data: [this.$store.getters.chart.quickInteractions.VISITED_INACTIVE],
          color: 'rgba(0, 0, 0, 0.2)',
          borderColor: 'rgba(0, 0, 0, 1)',
          borderWidth: 1,
        },
        {
          name: this.$t('quickInteraction.locked'),
          data: [this.$store.getters.chart.quickInteractions.LOCKED_ACTIVE],
          color: 'rgba(255,141,198, 0.8)',
        },
        {
          name: this.$t('quickInteraction.locked'),
          data: [this.$store.getters.chart.quickInteractions.LOCKED_INACTIVE],
          color: 'rgba(255,141,198, 0.2)',
          borderColor: 'rgba(255,141,198, 1)',
          borderWidth: 1,
        },
        {
          name: this.$t('quickInteraction.busy'),
          data: [this.$store.getters.chart.quickInteractions.BUSY_ACTIVE],
          color: 'rgba(66,144,107, 0.8)',
        },
        {
          name: this.$t('quickInteraction.busy'),
          data: [this.$store.getters.chart.quickInteractions.BUSY_INACTIVE],
          color: 'rgba(66,144,107, 0.2)',
          borderColor: 'rgba(66,144,107, 1)',
          borderWidth: 1,
        },
      ];
      this.chartOptions.series = data;
    },
    resizeChart() {
      if (this.$refs.highcharts) {
        this.$refs.highcharts.chart.reflow();
      }
    },
  },
};
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
}
</style>
