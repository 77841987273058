<template>
  <b-modal
    id="modal-view-photo"
    :title="$t('report.report')+' #'+reportId"
    button-size="sm"
  >

    <b-img
      fluid
      class="preview-photo-container"
      v-show="isLoad"
      :src="previewUrl"
      @load="loaded"
    ></b-img>

    <div
      class="loading-container"
      v-show="!isLoad"
      v-loading="true"
    ></div>
    <template #modal-footer="{ hide }">
      <b-button
        size="sm"
        variant="light"
        @click="hide()"
      >
        {{ $t('action.close') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "modal-view-photo",
  data() {
    return {
      isLoad: false,
      previewUrl: "",
      reportId: "",
    };
  },
  mounted() {
    // this.reportId = this.$store.getters.reportId;
  },
  methods: {
    loaded() {
      this.isLoad = true;
    },
  },
};
</script>
