<template>
  <b-modal
    id="modal-history"
    :title="$t('history.title')+ ' ' +$store.getters.reportRef"
    button-size="sm"
  >
    <History :reportUuid="$store.getters.reportUuid" />
    <template #modal-footer="{ hide }">
      <b-button
        size="sm"
        variant="light"
        @click="hide()"
      >
        {{ $t('action.close') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
// import History from '../Explore/HistoryComponents/History.vue';
import History from '@/components/Explore/SharedExploreComponents/History.vue';

export default {
  name: 'modal-history',
  components: {
    History,
  },
  data() {
    return {};
  },
  mounted() {
    // this.reportRef = this.$store.getters.reportRef;
    // alert(this.reportRef);
  },
  methods: {},
};
</script>
