<template>
  <div class="chart-container">
    <highcharts
      :options="chartOptions"
      ref="highchart"
    ></highcharts>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ProgressCurve',
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'line',
          height: '700',
          zoomType: 'x',
        },
        credits: {
          enabled: false,
        },
        title: {
          text: this.$t('stats.progressCurve'),
        },
        subtitle: {
          text: this.$t('stats.currentStatus'),
        },
        yAxis: {
          title: {
            text: this.$t('stats.deficiencies'),
          },
        },
        xAxis: {
          categories: [], // Filled dynamically
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
          },
          line: {
            dataLabels: {
              enabled: false,
            },
            marker: {
              enabled: false,
            },
            tooltip: {
              followPointer: true,
            },
          },
        },
        series: [],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                {
                  text: this.$t('highcharts.viewFullscreen'),
                  onclick: function () {
                    this.series[0].chart.fullscreen.toggle();
                  },
                },
                'separator',
                {
                  text: this.$t('highcharts.downloadPNG'),
                  onclick: function () {
                    this.exportChart({
                      type: 'image/png',
                    });
                  },
                },
              ],
            },
          },
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                chart: {
                  width: null,
                },
              },
            },
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters(['statusCounts']),
  },
  watch: {
    statusCounts: {
      handler(newVal) {
        this.updateChartOptions(newVal);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['fetchStatusCounts']),
    updateChartOptions(data) {
      if (data.length > 0) {
        const categories = data.map((item) => item[0]);
        const seriesNames = [
          {
            key: 'OPEN_status',
            name: this.$t('status.OPEN'),
            color: 'rgba(227, 159, 167, 1)',
          },
          {
            key: 'ADDRESSED_status',
            name: this.$t('status.ADDRESSED'),
            color: 'rgba(118, 205, 126, 1)',
          },
          {
            key: 'ONGOING_status',
            name: this.$t('status.ONGOING'),
            color: 'rgba(118, 205, 126, 1)',
          },
          {
            key: 'SOLVED_status',
            name: this.$t('status.SOLVED'),
            color: 'rgba(235, 218, 141, 1)',
          },
          {
            key: 'CLOSED_status',
            name: this.$t('status.CLOSED'),
            color: 'rgba(106, 212, 228, 1)',
          },
          {
            key: 'ONHOLD_status',
            name: this.$t('status.ONHOLD'),
            color: 'rgba(129, 148, 240, 1)',
          },
          {
            key: 'NOACTION_status',
            name: this.$t('status.NOACTION'),
            color: 'rgba(129, 148, 240, 1)',
          },
          {
            key: 'DELETED_status',
            name: this.$t('status.DELETED'),
            color: '#e5e5e5',
          },
        ];

        const series = seriesNames.map((status, index) => ({
          name: status.name,
          data: data.map((item) => item[index + 1]),
          color: status.color,
          dashStyle: status.dashStyle,
        }));

        // // Add the "REMAINING" data
        // const remainingData = data.map((item) => item[9]);
        // series.push({
        //   name: 'REMAINING',
        //   data: remainingData,
        //   color: '#000000',
        //   dashStyle: 'dot',
        // });

        this.chartOptions.xAxis.categories = categories;
        this.chartOptions.series = series;
      }
    },
    resizeChart() {
      if (this.$refs.highchart) {
        this.$refs.highchart.chart.reflow();
      }
    },
  },
  mounted() {
    const projectUuid = this.$route.params.projectUuid;
    this.fetchStatusCounts(projectUuid);
    window.addEventListener('resize', this.resizeChart);
    this.$nextTick(() => {
      this.resizeChart(); // Ensure chart resizes correctly on initial load
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeChart);
  },
};
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
}
</style>
