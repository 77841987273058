<template>
  <b-row class="mb-1">
    <b-col cols="3">
      <b-badge
        pill
        variant="light"
      >
        <font-awesome-icon :icon="['fal', 'hashtag']" />
        {{ hashtag }}
      </b-badge>
    </b-col>
    <b-col cols="5">
      <!-- Additional content can go here -->
    </b-col>
    <b-col
      cols="2"
      class="history-user"
    >
      {{ user }}
    </b-col>
    <b-col
      cols="2"
      class="history-date"
    >
      {{ date }}
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    hashtag: String,
    user: String,
    date: String,
  },
};
</script>