export const localRefMixin = {
  methods: {
    localRef(building, level, zone, serial, definition) {
      const projectUuid = this.$store.getters.currentProject.uuid;
      let ref;
      if (building === undefined || building === null) {
        building = '';
      }
      if (definition === undefined || definition === null) {
        definition = '';
      }
      ref = `${building} ${level}${zone}${serial} ${definition}`;
      return ref;
    },
  },
};
