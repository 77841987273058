<template>
  <b-row class="my-2">
    <b-col
      cols="3"
      class="d-flex align-items-center"
    >
      <b-badge
        variant="light"
        class="d-flex align-items-center w-100"
      >
        <font-awesome-icon
          :icon="['fal', 'comment']"
          size="lg"
        />
        <span
          :class="iconClass"
          class="history-status"
        >
          {{ commentStatus }}
        </span>
      </b-badge>
    </b-col>
    <b-col
      cols="5"
      class="history-detail"
    >
      {{ comment }}
    </b-col>
    <b-col
      cols="2"
      class="history-user d-flex align-items-center"
    >
      {{ user }}
    </b-col>
    <b-col
      cols="2"
      class="history-date d-flex align-items-center"
    >
      {{ date }}
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    actionType: String,
    comment: String,
    user: String,
    date: String,
  },
  computed: {
    commentStatus() {
      return this.actionType === 'COMMENT+'
        ? this.$t('history.added')
        : this.$t('history.removed');
    },
    iconClass() {
      return this.actionType === 'COMMENT+'
        ? 'history-text-success'
        : 'history-text-primary';
    },
  },
};
</script>
