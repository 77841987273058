<template>
  <div class="chart-container">
    <!-- Show a loading message while waiting for data -->
    <div v-if="loading">
      <p>Loading chart data...</p>
    </div>

    <!-- Render the chart only when data is available -->
    <highcharts
      v-if="!loading && chartDataAvailable"
      :options="chartOptions"
      ref="highcharts"
    ></highcharts>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';

export default {
  name: 'statusAP',
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      loading: true, // Initial loading state
      chartDataAvailable: false, // Flag to control chart rendering
      chartOptions: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false,
          height: '100%',
        },
        credits: {
          enabled: false,
        },
        title: {
          text: 'Status',
          align: 'left',
          style: { fontSize: '16px' },
        },
        subtitle: {
          text: this.$t('highcharts.overPeriod'),
          align: 'left',
          style: { fontSize: '10px' },
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
        },
        accessibility: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            showInLegend: true,
            dataLabels: {
              enabled: true,
              distance: -40,
              style: {
                fontWeight: 'bold',
                color: 'white',
              },
              formatter: function () {
                return (
                  '<span style="fontWeight: bold; color: white;">' +
                  this.point.name +
                  '</span><br/>' +
                  '<span style="color: white; font-weight: normal;">' +
                  Math.round(this.percentage * 10) / 10 +
                  '%</span>'
                );
              },
              useHTML: true,
            },
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '75%'],
            size: '110%',
          },
        },
        series: [
          {
            type: 'pie',
            name: 'Status',
            innerSize: '50%',
            data: [], // Initially empty data
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                {
                  text: this.$t('highcharts.viewFullscreen'),
                  onclick: function () {
                    this.series[0].chart.fullscreen.toggle();
                  },
                },
                'separator',
                {
                  text: this.$t('highcharts.downloadPNG'),
                  onclick: function () {
                    this.exportChart({
                      type: 'image/png',
                    });
                  },
                },
              ],
            },
          },
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                chart: {
                  width: null,
                },
              },
            },
          ],
        },
      },
    };
  },
  computed: {
    // Get the current status data from Vuex
    statusAPData() {
      return this.$store.getters.chart.statusAP;
    },
  },
  watch: {
    // Watch for changes in the statusAP data and update the chart
    statusAPData: {
      handler(newStatus) {
        if (newStatus && typeof newStatus.ACTIVE !== 'undefined') {
          this.updateChartData(newStatus); // Update the chart when the data changes
        }
      },
      immediate: true, // Run the watcher immediately on mount
      deep: true, // Deep watch to handle nested changes
    },
  },
  methods: {
    updateChartData(status) {
      // Prepare the data for the chart
      const data = [
        { name: this.$t('highcharts.active'), y: Number(status.ACTIVE) },
        { name: this.$t('highcharts.passive'), y: Number(status.PASSIVE) },
      ];

      // Update chart data
      this.chartOptions.series[0].data = data;
      this.chartDataAvailable = true; // Mark data as available
      this.loading = false; // Stop loading

      // Ensure the chart is resized correctly after rendering
      this.$nextTick(() => {
        this.resizeChart();
      });
    },
    resizeChart() {
      if (this.$refs.highcharts && this.$refs.highcharts.chart) {
        this.$refs.highcharts.chart.reflow();
      }
    },
  },
  mounted() {
    // Fetch the initial data from Vuex
    this.$store.dispatch('fetchStats', 'statusAP');

    // Add event listener for resizing the chart on window resize
    window.addEventListener('resize', this.resizeChart);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeChart);
  },
};
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
}
</style>
