<template>
  <div>
    <b-card class="mb-3 mt-3 card-goso-stats">

      <ProgressCurve />
    </b-card>
  </div>
</template>
<script>
import ProgressCurve from './Curve/ProgressCurve.vue';

export default {
  name: 'curve-view',
  components: {
    ProgressCurve,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.card-body {
  padding: 0 !important;
}
</style>

