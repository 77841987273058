<template>
  <b-row class="mb-1">
    <b-col
      cols="3"
      class="d-flex align-items-center"
    >
      <status
        :status="status"
        :target="target"
      />
    </b-col>
    <b-col cols="5">
      <!-- Additional content can go here -->
    </b-col>
    <b-col
      cols="2"
      class="history-user d-flex align-items-center"
    >
      {{ user }}
    </b-col>
    <b-col
      cols="2"
      class="history-date d-flex align-items-center"
    >
      {{ date }}
    </b-col>
  </b-row>
</template>

<script>
import Status from './Status.vue';

export default {
  components: {
    Status,
  },
  props: {
    status: String,
    target: String,
    user: String,
    date: String,
  },
};
</script>
