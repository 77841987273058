<template>
  <b-container fluid>
    <b-form @submit.prevent="submit">

      <div>

        <b-form-group>
          <b-form-input
            type="email"
            class="form-control bg-white"
            id="username"
            v-model="form.username"
            :placeholder="$t('auth.email')"
            @blur="$v.form.username.$touch"
          ></b-form-input>
          <label
            class="error"
            v-if="(!$v.form.username.required || !$v.form.username.email) && $v.form.username.$dirty"
          >{{ $t('validate.emailValid') }}</label>
        </b-form-group>

        <b-form-group>
          <b-form-input
            type="password"
            class="form-control bg-white"
            id="password"
            v-model="form.password"
            :placeholder="$t('auth.password')"
            @blur="$v.form.password.$touch"
          >
          </b-form-input>
          <label
            class="error"
            v-if="!$v.form.password.required && $v.form.password.$dirty"
          >{{ $t('validate.required') }}</label>
        </b-form-group>

      </div>

      <b-button
        type="submit"
        variant="primary"
        size="lg"
        block
        class="mb-3"
      >{{ $t('auth.login') }}</b-button>

      <b-row>
        <b-col cols="8">
          <router-link
            to="/auth/passwordinit"
            class="link footer-link"
          >
            {{ $t('auth.passwordforgot') }}
          </router-link>
        </b-col>
        <b-col
          cols="4"
          class="d-flex justify-content-end"
        >
          <b-dropdown
            id="dropdown"
            variant="link"
            right
          >
            <template #button-content>
              <img
                :src="require(`@/assets/img/flag/flag_${i18n}.svg`)"
                alt="Lauguage flag"
              >
            </template>
            <b-dropdown-item @click.prevent="setLocale('fr')"><img
                :src="require('@/assets/img/flag/flag_fr.svg')"
                alt="French flag"
              >
              <span class="ml-2">{{ $t('navbar.french') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click.prevent="setLocale('en')"><img
                :src="require('@/assets/img/flag/flag_en.svg')"
                alt="English flag"
              >
              <span class="ml-2">{{ $t('navbar.english') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-col>

      </b-row>
    </b-form>

  </b-container>

</template>
<script>
import axiosAuth from '@/utils/axiosAuth';
import { required, email } from 'vuelidate/lib/validators';
import moment from 'moment';

export default {
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
      i18n: this.$root.$i18n.locale,
    };
  },
  validations: {
    form: {
      username: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  mounted() {
    // Clear userLogged for seccurity reasons
    // this.$store.dispatch('clearUserLogged');

    const clgStyle = 'color: white; background-color: green;';
    console.log('%c' + `MODE= ${process.env.NODE_ENV}`, clgStyle);
    // const date = moment('2022-11-29');
    // const now = moment();
    // if (now > date) {
    //   alert('delete');
    // }
    const lastReport_A = this.$store.getters.lastReport_A;
    if (typeof lastReport_A === 'object' && !Array.isArray(lastReport_A)) {
      // 👇️ this runs
      console.log('✅ lastReport_A is an object');
    } else {
      console.log('⛔️ lastReport_A is NOT an object => Reset store');
      this.$store.commit('resetLastReportState');
    }
  },
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.submitStatus = 'ERROR';
      } else {
        this.$store.commit('loading', true);
        // do your submit logic here
        console.log('Trying to LOG IN');
        axiosAuth
          .post('/auth/login', this.form)
          .then((response) => {
            console.log('HERE');
            this.$store.commit('loading', false);
            console.log(response.data);
            if (response.data.success === false) {
              this.$globalSwal.error('error.sorry', response.data.msg);
            } else {
              console.log(response.data.results.userLogged);
              console.log(response.data.results.projects);
              console.log(response.data.token);

              // // If logged user is different from previous logged uer, clear last route which will cause the user to be rerouted to home
              if (
                response.data.results.userLogged.uuid !=
                this.$store.getters.loggedUser.uuid
              ) {
                this.$store.commit('CLEAR_lastSubsectionRoute');
                // Also reset current project

                this.$store.commit(
                  'setCurrentProject',
                  response.data.results.projects[0]
                );
              }
              // Set logged in in store
              this.$store.commit('logIn', true);
              // // Save token in store
              const accessToken = response.data.token.accessToken;
              this.$store.commit('setAccessToken', accessToken);

              // Save loggedUser in store
              this.$store.dispatch(
                'saveUserLogged',
                response.data.results.userLogged
              );

              this.$store.commit('setProjects', response.data.results.projects);

              // console.log(
              //   'Store userloggedId AFTER',
              //   this.$store.getters.loggedUser,
              // );
              // Empty sitaAccess and Save user access in store

              const lastSubsectionRoute =
                this.$store.getters.lastSubsectionRoute;
              console.log('lastSubsectionRoute', lastSubsectionRoute);

              if (this.$store.getters.currentProject.length != 0) {
                // Go to home or to lastSubsectionRoute
                if (lastSubsectionRoute != '') {
                  this.$router.push(lastSubsectionRoute);
                } else {
                  this.$router.push('/home/home/homePage');
                }
                // Set alert
                this.$store.commit('setAlertAcknowledgment', false);
              } else {
                // Set first project as default currentProject
                this.$store.commit(
                  'setCurrentProject',
                  response.data.results.projects[0]
                );
                // Go to project selection page
                this.$router.push('/home/home/homePage');
              }
            }
          })
          .catch((err) => {
            this.$store.commit('loading', false);
            this.$globalSwal.error(err.response.data.msg);
            console.log(err.response.data.msg);
            // this.$globalSwal.error(
            //   'error',
            //   err.response.data.msg,
            //   'error.persists',
            // );
          });

        this.submitStatus = 'PENDING';
        setTimeout(() => {
          this.submitStatus = 'OK';
        }, 500);
      }
    },
    setLocale(locale) {
      // Set i18n locale global variable
      this.$i18n.locale = locale;
      // Store locale in localstorage
      localStorage.setItem('lang', locale);
      console.log(`changed locale to ${locale}`);
      this.i18n = locale;
    },
  },
  beforeCreate() {
    this.$store.commit('loading', false);
  },
};
</script>
