<template>
  <b-row class="my-2">
    <b-col
      cols="3"
      class="d-flex align-items-center"
    >

      <div
        :class="`status-${status} status-${target}`"
        class="status-container"
      >{{ $t('status.'+status) }}</div>

    </b-col>
    <b-col
      cols="5"
      class="history-detail"
    >
      <font-awesome-icon
        :icon="['fal', 'envelope']"
        size="lg"
        v-if="byEmail"
      />
      {{ toEntity }} {{ formatToUser(toUser) }}

    </b-col>
    <b-col
      cols="2"
      class="history-user d-flex align-items-center"
    >
      {{ user }}
    </b-col>
    <b-col
      cols="2"
      class="history-date d-flex align-items-center"
    >
      {{ date }}
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    actionType: String,
    comment: String,
    toUser: String,
    toEntity: String,
    user: String,
    date: String,
    status: String,
    target: String,
    byEmail: String,
  },
  methods: {
    formatToUser(toUser) {
      return toUser ? `(${toUser})` : '';
    },
  },
  computed: {
    commentStatus() {
      return this.actionType === 'COMMENT+'
        ? this.$t('history.added')
        : this.$t('history.removed');
    },
    iconClass() {
      return this.actionType === 'COMMENT+'
        ? 'history-text-success'
        : 'history-text-primary';
    },
  },
};
</script>
