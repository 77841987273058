<template>
  <b-row class="mb-5">
    <b-col
      cols="6"
      lg="4"
    >
      <h4>{{ $t('create.text.consequence') }}</h4>
      <b-form-radio-group
        id="btn-radios-severity"
        class=""
        v-model="severitySelected"
        :options="severityOptions"
        @change="calculatePriority"
        size="lg"
        name="radio-btn-outline"
        buttons
        stacked
      ></b-form-radio-group>
    </b-col>
    <b-col
      cols="6"
      lg="4"
    >
      <h4>{{ $t('create.text.probability') }}</h4>
      <b-form-radio-group
        id="btn-radios-probability"
        class="mb-4"
        v-model="probabilitySelected"
        :options="probabilityOptions"
        @change="calculatePriority"
        size="lg"
        name="radio-btn-outline"
        buttons
        stacked
        block
      ></b-form-radio-group>
    </b-col>
    <b-col
      cols="12"
      lg="4"
    >
      <h4>{{ $t('create.text.priority') }}</h4>
      <b-card class="mb-2">
        <b-card-text>
          <h3>
            <b-badge :style="{ backgroundColor: priorityColor, color: priorityTextColor }">
              {{ priorityText }} </b-badge>
          </h3>
          {{ priorityDescription }}
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'priority',
  props: {
    report: Object,
    mode: String,
  },
  data() {
    return {
      probabilitySelected: '1',
      probabilityOptions: [
        { text: this.$t('create.probability.improbable'), value: '1' },
        { text: this.$t('create.probability.rare'), value: '2' },
        { text: this.$t('create.probability.possible'), value: '3' },
        { text: this.$t('create.probability.high'), value: '4' },
        { text: this.$t('create.probability.certain'), value: '5' },
      ],
      severitySelected: '1',
      severityOptions: [
        { text: this.$t('create.consequence.insignificant'), value: '1' },
        { text: this.$t('create.consequence.minor'), value: '2' },
        { text: this.$t('create.consequence.moderate'), value: '3' },
        { text: this.$t('create.consequence.major'), value: '4' },
        { text: this.$t('create.consequence.catastrophic'), value: '5' },
      ],
      priorityText: '',
      priorityDescription: '',
      priorityColor: '',
      priorityTextColor: '',
      priority: null,
    };
  },
  methods: {
    calculatePriority() {
      let factor =
        (this.severitySelected - 1) * 5 + (this.probabilitySelected - 1);

      if (factor <= 1) {
        this.setPriority('INFO', 'gray', 'white');
      } else if (factor === 2 || factor === 5) {
        this.setPriority('LONGTERM', 'blue', 'white');
      } else if (
        (factor >= 6 && factor <= 7) ||
        (factor >= 10 && factor <= 11)
      ) {
        this.setPriority('MEDIUMTERM', 'yellow', 'black');
      } else if (
        factor === 3 ||
        (factor >= 8 && factor <= 9) ||
        (factor >= 12 && factor <= 14) ||
        factor === 15
      ) {
        this.setPriority('SHORTTERM', 'orange', 'white');
      } else if (factor >= 16) {
        this.setPriority('URGENT', 'red', 'white');
      } else {
        this.setPriority('INFO', 'gray', 'white');
      }
    },
    setPriority(priority, color, textColor) {
      this.priorityText = this.$t(`priority.${priority.toLowerCase()}.value`);
      this.priorityDescription = this.$t(
        `priority.${priority.toLowerCase()}.description`
      );
      this.priorityColor = color;
      this.priorityTextColor = textColor;
      this.priority = priority;
    },
  },
  watch: {
    report: {
      immediate: true,
      handler(newReport) {
        if (this.mode === 'edit' && newReport) {
          const clgStyle = 'color: blue;';
          console.log('%c' + 'PRIORITY HANDLING', clgStyle);
          console.log(
            `severity: ${newReport.severity}, probability: ${newReport.probability}`
          );
          this.severitySelected = newReport.severity || '1';
          this.probabilitySelected = newReport.probability || '1';
          this.calculatePriority();
        }
      },
    },
  },
  mounted() {
    if (this.mode === 'edit' && this.report) {
      this.severitySelected = this.report.severity || '1';
      this.probabilitySelected = this.report.probability || '1';
    }
    this.calculatePriority();
  },
};
</script>
