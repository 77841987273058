<template>
  <div>
    <!-- LIKE - Active -->
    <font-awesome-layers
      class="quick-like result-table"
      v-if="quickType == 'like.active'"
    >
      <font-awesome-icon
        icon="fa-solid fa-circle"
        size="2x"
      />
      <font-awesome-icon
        icon="fa-solid fa-thumbs-up"
        transform="up-1 right-9"
        class="white"
      />
    </font-awesome-layers>
    <!-- LIKE - Inactive -->
    <font-awesome-layers
      class="quick-like result-table"
      v-if="quickType == 'like.inactive'"
    >
      <font-awesome-icon
        icon="fa-thin fa-circle"
        size="2x"
      />
      <font-awesome-icon
        icon="fa-light fa-thumbs-up"
        transform="up-1 right-8"
      />
    </font-awesome-layers>

    <!-- VISITED - Active -->
    <font-awesome-layers
      class="quick-visited result-table"
      v-if="quickType == 'visited.active'"
    >
      <font-awesome-icon
        icon="fa-solid fa-circle"
        size="2x"
      />
      <font-awesome-icon
        icon="fa-solid fa-person-walking"
        transform="up-1 right-8"
        class="white"
      />
    </font-awesome-layers>
    <!-- VISITED - Inactive -->
    <font-awesome-layers
      class="quick-visited"
      v-if="quickType == 'visited.inactive'"
    >
      <font-awesome-icon
        icon="fa-thin fa-circle"
        size="2x"
      />
      <font-awesome-icon
        icon="fa-light fa-person-walking"
        transform="up-1 right-8"
      />
    </font-awesome-layers>

    <!-- LOCKED - Active -->
    <font-awesome-layers
      class="quick-locked"
      v-if="quickType == 'locked.active'"
    >
      <font-awesome-icon
        icon="fa-solid fa-circle"
        size="2x"
      />
      <font-awesome-icon
        icon="fa-light fa-lock"
        transform="up-1 right-8"
        class="white"
      />
    </font-awesome-layers>
    <!-- LOCKED - Inactive -->
    <font-awesome-layers
      class="quick-locked"
      v-if="quickType == 'locked.inactive'"
    >
      <font-awesome-icon
        icon="fa-thin fa-circle"
        size="2x"
      />
      <font-awesome-icon
        icon="fa-light fa-lock"
        transform="up-1 right-8"
      />
    </font-awesome-layers>

    <!-- BUSY - Active -->
    <font-awesome-layers
      class="quick-busy"
      v-if="quickType == 'busy.active'"
    >
      <font-awesome-icon
        icon="fa-solid fa-circle"
        size="2x"
      />
      <font-awesome-icon
        icon="fa-light fa-ban"
        transform="right-8"
        class="white"
      />
    </font-awesome-layers>
    <!-- BUSY - Inactive -->
    <font-awesome-layers
      class="quick-busy"
      v-if="quickType == 'busy.inactive'"
    >
      <font-awesome-icon
        icon="fa-thin fa-circle"
        size="2x"
      />
      <font-awesome-icon
        icon="fa-light fa-ban"
        transform="right-8"
      />
    </font-awesome-layers>

  </div>
</template>
<script>
export default {
  name: 'quick',
  props: ['quickType'],
};
</script>
